import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import useCache from 'hooks/useCache'
import GET_PAGE from './graphql/getReadingsPage.graphql'
import GET_STATS from './graphql/getReadingStats.graphql'
import GET_RANGE from './graphql/getReadingsRange.graphql'
import GET_BOOKS from './graphql/getBooks.graphql'

import { useStudentPage } from '../../../index.state'
import useRange from '../../hooks/useRange'

interface IBook {
  _id: string
  title: string
  imageUrl: string
}
interface IBookRecord {
  _id: string
  book: IBook
  createdAt: Date
  text: string
  timeSpent: number
}


interface ReadingStatPoint {
  timestamp?: number
  nbRecords: number
}

const ReadingsState = () => {
  const client = useApolloClient()
  const { student } = useStudentPage()

  const [books, setBooks] = useState<IBook[]>([])

  const [bookRecords, setBookRecords] = useState<IBookRecord[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [loadingStats, setLoadingStats] = useState<boolean>(false)
  const [thisMonthStats, setThisMonthStats] = useState<ReadingStatPoint | null>(null)
  const [overallStats, setOverallStats] = useState<ReadingStatPoint | null>(null)

  const [loadingRange, setLoadingRange] = useState<boolean>(false)
  const [range, setRange] = useState<ReadingStatPoint[]>([])
  const { rangeSettings, nextMonth, previousMonth, canNext, canPrevious } = useRange({ minDate: student.createdAt })

  const LAST_REFRESH_CACHE_KEY = `${student.username}_reading`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  const PAGE_SIZE = 6

  const fetchPage = async (page: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_PAGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        skip: PAGE_SIZE * (page - 1),
        limit: PAGE_SIZE
      }
    })
    return data?.findUser?.bookRecords || []
  }
  
  const loadPage = async (page: number, refetch?: boolean) => {
    if (loadingMore) return
    setLoadingMore(true)
    const bookRecords = await fetchPage(page, refetch) 
    setLoadingMore(false)
    setHasMore(bookRecords.length >= PAGE_SIZE)
    setBookRecords(b => ([...b, ...bookRecords]))
    return true
  }

  const refreshFirstPage = async (refetch?: boolean) => {
    const fetched = await fetchPage(1, refetch)
    setBookRecords((bookRecords) => {
      const lastMap = bookRecords.slice(0, PAGE_SIZE).reduce((acc, bookRecord) => {
        acc[bookRecord._id] = bookRecord
        return acc
      }, {})
      const { existing, added } = fetched.reduce((acc, bookRecord) => {
        if (lastMap[bookRecord._id]) {
          acc.existing.push(bookRecord)
        } else {
          acc.added.push(bookRecord)
        }
        return acc
      }, { existing: [], added: [] })
    
      return [
        ...added,
        ...existing,
        ...bookRecords.slice(PAGE_SIZE, bookRecords.length - 1)
      ]
    })
  }

  const loadStats = async (refetch?: boolean) => {
    setLoadingStats(true)
    const thisMonthTo = moment().startOf('day')
    const thisMonthFrom = moment().startOf('month')
  
    const { data } = await client.query({
      query: GET_STATS,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        thisMonthFrom,
        thisMonthTo,
      },
      errorPolicy: 'all'
    })
    if (data.findUser.readingStats) {
      setOverallStats(data.findUser.readingStats.stats)
      setThisMonthStats(data.findUser.readingStats.thisMonthStats)
    }
    setLoadingStats(false)
  }

  const loadRange = useCallback(async (refetch?: boolean) => {
    setLoadingRange(true)
    const { data } = await client.query({
      query: GET_RANGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from: rangeSettings.from,
        to: rangeSettings.to,
        by: rangeSettings.by
      },
      errorPolicy: 'all'
    })
    if (data.findUser.readingStats) {
      setRange(data.findUser.readingStats.range)
    }
    setLoadingRange(false)
  }, [rangeSettings])

  useEffect(() => {
    if (rangeSettings.updated) {
      loadRange()
    }
  }, [rangeSettings])

  const loadBooks = async (refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_BOOKS,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
      },
      errorPolicy: 'all'
    })
    if (data.findUser.books) {
      setBooks(data.findUser.books)
    }
  }

  const refresh = useMemo(() => {
    if (canRefresh) {
      return () => {
        loadStats(true)
        loadBooks(true)
        loadRange(true)
        refreshFirstPage(true)
        update(Date.now())
      }
    }
    return undefined
  }, [canRefresh])

  return {
    // Feed
    loadPage,
    loadingMore,
    hasMore: !loadingMore && hasMore,
    bookRecords,
    // Stats
    loadStats,
    loadingStats,
    thisMonthStats,
    overallStats,
    // Range
    loadingRange,
    rangeSettings,
    rangePreviousMonth: previousMonth,
    rangeNextMonth: nextMonth,
    canNextMonth: canNext,
    canPreviousMonth: canPrevious,
    loadRange,
    range,
    // Books
    loadBooks,
    books,
    lastRefresh,
    refresh,
  }
}

const ReadingsContainer = createContainer(ReadingsState)
export const ReadingsProvider = ReadingsContainer.Provider
export const useReadings = ReadingsContainer.useContainer