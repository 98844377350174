import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../../../../../UI/Loader'
import Writing from '../../../../../UI/Writing'
import { useWriting } from './useWriting'
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  > * {
    height: 100%;
  }
  > * > * {
    margin-bottom: 25px;
  }
  max-width: 900px;
  @media only screen and (max-width: 1024px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 780px) {
    max-width: 100%;
  }
  font-family: 'Rammetto One', cursive;
`

const WritingList = () => {
  const { writings, loadPage, hasMore, loadingMore } = useWriting()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new writing...' />}
      >
        { writings.length === 0 && !loadingMore && <EmptyFeed>No writing to see!</EmptyFeed>}
        {writings.map((w) => (
          <Writing key={w._id} {...w} />
        ))}
      </InfiniteScroll>
    </Wrapper>
  )
}

export default WritingList
