import React from 'react'
import styled from 'styled-components'
import Box from './Box'

const Content = styled.div`
  background-color: #502566;
  color: #fff;
  display: flex;
  padding: 5px 15px;
`

const Column = styled.div`
  width: 120px;
  > :first-child {
    margin-bottom: 6px;
  }
  > :nth-child(2) {
    font-size: 28px;
  }
`

interface IProps {
  followers: any[]
  following: any[]
}

const Overview = (props: IProps) => {
  const { following, followers } = props

  return (
    <Box>
      <Content>
        <Column>
          <div>Followers</div>
          <div>{followers.length}</div>
        </Column>
        <Column>
          <div>Following</div>
          <div>{following.length}</div>
        </Column>
      </Content>
    </Box>
  )
}

export default Overview
