import React from 'react'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  nbCompleted: number,
  // eslint-disable-next-line react/no-unused-prop-types
  completedThisMonth: number,
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

const NUMBERS = (props: IProps) => [
  { label: 'Total completed', value: `${props.nbCompleted}` },
  { label: 'Completed this month', value: `${props.completedThisMonth}` },
]

export const PRIMARY = '#1E2958'

const LessonsCard = (props: IProps) => {
  return <NumbersCard actions={props.actions} bgColor={PRIMARY} numbers={NUMBERS(props)} loading={props.loading} />
}

LessonsCard.defaultProps = {
  actions: [],
  loading: false,
}

export default LessonsCard
