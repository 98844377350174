import React from 'react'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  nbDrawings: number,
  // eslint-disable-next-line react/no-unused-prop-types
  drawingsThisMonth: number,
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

export const PRIMARY = '#CC6118'

const NUMBERS = (props: IProps) => [
  { label: 'Total drawings', value: `${props.nbDrawings}` },
  { label: 'Drawings this month', value: `${props.drawingsThisMonth}` },
]
const DrawingsCard = (props: IProps) => {
  return <NumbersCard actions={props.actions} bgColor={PRIMARY} numbers={NUMBERS(props)} loading={props.loading}  />
}

DrawingsCard.defaultProps = {
  actions: [],
  loading: false,
}

export default DrawingsCard
