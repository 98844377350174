import { getLuminance } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Loader from 'components/UI/Loader'

const Wrapper = styled.div`
  background-color: #1E2958;
  padding: 15px 0 30px 0;
  border-radius: 14px;
  width: 100%;
`

const Content = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 10px 20px 10px;
  section {
    font-size: 18px;
    line-height: 30px;
    > :first-child {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 8px;
    }
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 8px; margin-bottom: 8px; }
`

const Tag = styled.div`
  border-radius: 50px;
  padding: 2px 12px;
  font-size: 14px;
`

interface IProps {
  lesson?: {
    _id: string
    tags: {
      _id: string
      value: string
      color: string
    }[]
  }
  loading?: boolean
}

const RecentTags = (props: IProps) => {

  return <Wrapper>
    <Content>
      <section className='first-section'>
        <div>Recent learning areas</div>
        { props.loading && <Loader color='#ffffff55' minHeight={100} size={60} /> }
        { !props.loading && !props.lesson && <p className='info'>Learning areas will show up here.</p> }
        { !props.loading && props.lesson?.tags && <Tags>
            { (props.lesson.tags || []).map(tag => <Tag key={tag._id} style={{
              backgroundColor: tag.color,
              color: getLuminance(tag.color) > 0.5 ? '#000' : '#ffff'
            }}>{tag.value}</Tag>)}
            </Tags> }
      </section>
    </Content>
  </Wrapper>
}

RecentTags.defaultProps = {
  lesson: null,
  loading: false,
}

export default RecentTags
