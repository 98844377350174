import React, { useMemo } from 'react'
import styled from 'styled-components'
import { StudentPageState } from '../index.state'
// @ts-ignore
import { EditorElement } from './index.styles'

const Wrapper = styled(EditorElement)`
` 

const YEAR_TO_AGE = [
  { value: 0, label: '4-5' },
  { value: 1, label: '5-6' },
  { value: 2, label: '6-7' },
  { value: 3, label: '7-8' },
  { value: 4, label: '8-9' },
  { value: 5, label: '9-10' },
  { value: 6, label: '10-11' },
  { value: 7, label: '12+' },
]

const Age = () => {
  const { student } = StudentPageState.useContainer()

  const age = useMemo(() => {
    if (student.birthYear && student.birthMonth) {
      return `${new Date().getUTCFullYear() - student.birthYear - (student.birthMonth <= new Date().getMonth() + 1 ? 0 : 1)} years old`
    }
    if (YEAR_TO_AGE[student.yearGroup]) {
      return `${YEAR_TO_AGE[student.yearGroup].label} years old`
    }
    return 'Unknown age'
  }, [student])

  return <Wrapper>
    <div>
      <div>Age</div>
      <p>{age}</p>
    </div>
  </Wrapper>
}

export default Age
