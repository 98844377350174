import moment from 'moment'
import React, { useMemo } from 'react'
import { LineChart, XAxis, Tooltip, Line, ResponsiveContainer, Label, YAxis } from 'recharts'
import styled from 'styled-components'
import Loader from 'components/UI/Loader'
import ChartNoData from '../../components/UI/ChartNoData'

const Wrapper = styled.div`
  background-color: #fff;
  height: 240px;
  width: 100%;
  > * { font-size: initial; }
  background-color: #00000033;
  color: #ffffff88;
  padding: 15px;
  border-radius: 10px;
  .recharts-label {
    tspan { text-anchor: middle; }
  }

  .custom-tooltip {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    font-size: 11px;
    line-height: 18px;
    padding: 3px 7px;
    color: #fff;
  }
`

const LoaderWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomTooltip = ({ active, payload, label, by }: { active: boolean, payload: any[], label: number, by: string }) => {
  if (active && payload && payload.length) {
    let date: string | number = label
    if (by === 'MONTH') date = moment(label).format('MMM YY')
    if (by === 'YEAR') date = moment(label).format('YYYY')
    if (by === 'DAY') date = moment(label).format('DD MMM YY')
    return (
      <div className="custom-tooltip">
        <div className='label'>{date}</div>
        <div className="label">{`Drawing: ${payload[0].payload.nbDrawings}`}</div>
      </div>
    );
  }

  return null;
}

export default ({ data, by, loading }: { data: any, by: string, loading: boolean }) => {
  const config = useMemo(() => {
    const tickFormatter = (t) => {
      if (by === 'MONTH') {
        return moment(t).format('MMM')
      }
      if (by === 'YEAR') {
        return moment(t).format('YY')
      }
      return moment(t).format('Do')
    }
    
    let name = ''
    if (by === 'MONTH') name = 'Months'
    if (by === 'YEAR') name = 'Years'

    return {
      tickFormatter,
      name
    }
  }, [by])

  if (loading) {
    return <LoaderWrapper>
      <Loader color='rgba(255, 255, 255, 0.5)' />
    </LoaderWrapper>
  }
  if (data.length === 0) {
    return <ChartNoData />
  }
  return <Wrapper>
    <ResponsiveContainer width='100%' height="100%">
      <LineChart
        data={data}
        margin={{ top: 5, right: 20, left: 0, bottom: 20 }}
      >
        { data && <XAxis
          dataKey="timestamp"
          stroke='#FFFFFF88'
          tickCount={5}
          type='number'
          interval='preserveEnd'
          domain={[data[0]?.timestamp, data[data.length - 1]?.timestamp]}
          tickFormatter={config.tickFormatter}
        > 
          <Label value={config.name} offset={0} position="bottom" fill='#FFFFFF88' />
        </XAxis> }
        <YAxis stroke='#FFFFFF88'>
          <Label value="Completed" angle={-90} offset={14} position="insideLeft" fill='#FFFFFF88' />
        </YAxis>
        { /* @ts-ignore */ }
        <Tooltip content={<CustomTooltip by={by} />} />
        <Line type="linear" dataKey="nbDrawings" stroke="#fff" yAxisId={0}
          dot
          activeDot  
        />
      </LineChart>
    </ResponsiveContainer>
  </Wrapper>
}