import { Button, Challenges, Controller, DrawingTask, Lessons, MyZoo, Torch, Writing } from 'nzk-react-components'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

export type IconType = 'writing' | 'lessons' | 'challenges' | 'drawing-task' | 'controller' | 'torch' | 'my-zoo'

enum IconTypeEnum {
  writing = 'writing',
  lessons = 'lessons',
  challenges = 'challenges',
  'drawing-task' = 'drawing-task',
  controller = 'controller',
  torch = 'torch',
  'my-zoo' = 'my-zoo',
}

interface IProps {
  icon: IconType
  height: number
}


const configs: {
  [key in IconTypeEnum]: {
    backgroundColor: string,
    iconName: string,
    icon: ReactElement
  }
} = {
  'writing': {
    iconName: 'writing',
    backgroundColor: '#99D131',
    icon: <Writing />
  },
  'lessons': {
    iconName: 'lessons',
    backgroundColor: '#28348A',
    icon: <Lessons />
  },
  'challenges': {
    iconName: 'challenges',
    backgroundColor: '#B70620',
    icon: <Challenges />
  },
  'drawing-task': {
    iconName: 'drawing-task',
    backgroundColor: '#F79326',
    icon: <DrawingTask />
  },
  'my-zoo': {
    iconName: 'my-zoo',
    backgroundColor: '#F79326',
    icon: <MyZoo />
  },
  'controller': {
    iconName: 'controller',
    backgroundColor: '#E51F37',
    icon: <Controller />
  },
  'torch': {
    iconName: 'torch',
    backgroundColor: '#EDD034',
    icon: <Torch />
  }
}

const Wrapper = styled.div`

`


const RoundIcon = (props: IProps) => {
  const config = configs[props.icon]
  return <Wrapper>
    <Button round height={props.height} backgroundColor={config.backgroundColor}>
      { config.icon }
    </Button>
  </Wrapper>
}

export default RoundIcon
