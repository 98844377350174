import { navigate } from 'gatsby'
import RoundIcon, { IconType } from 'components/UI/RoundIcon'
import { ArrowLeft, Button, Refresh } from 'nzk-react-components'
import React from 'react'
import { FormattedRelativeTime } from 'react-intl'
import styled from 'styled-components'
import { useStudentPage } from '../../index.state'

interface IProps {
  icon?: IconType
  children: React.ComponentElement<any, any>
  title?: string
  onBack?: () => void
  refresh?: () => void
  lastRefresh?: number
}

const Wrapper = styled.div`
  padding-top: 20px;
`

const Header = styled.div`
  position: relative;
  .header--back-button {
    position: absolute;
    top: 0;
    left: 0;
  }
  .header--profile-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .header--title-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header--title {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    font-family: 'Rammetto One', cursive;
    margin-bottom: 0px;
    text-align: center;
    .header--title-icon {
      margin-right: 10px;
    }
    span {
      font-size: 22px;
      line-height: 26px;
      text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
    }
    .header--title-refresh {
      margin-left: 15px;
      padding-top: 5px;
    }
  }
  .header--last-refresh {
    color: #fff;
    font-size: 14px;
    font-family: 'Libre Baskerville';
  }
`

const Tab = (props: IProps) => {
  const { student } = useStudentPage()
  const defaultBack = () => {
    navigate(-1)
  }

  const onBack = props.onBack || defaultBack

  const visitProfile = () => window.open(`https://www.nightzookeeper.com/edu/zoo/profile/${student.username}`)

  return <Wrapper>
    <Header>
      <div className='header--back-button'>
        <Button size='x-small' theme='primary' round onClick={onBack}>
          <ArrowLeft />
        </Button>
      </div>
      <div className='header--profile-button'>
        <Button size='x-small' theme='purple' onClick={visitProfile}>
          Profile
        </Button>
      </div>
      <div className='header--title-group'>
        <div className='header--title'>
          { props.icon && <div className='header--title-icon'>
            <RoundIcon height={30} icon={props.icon} />
          </div> }
          <span>{ props.title }</span>
          { props.refresh && <div className='header--title-refresh'>
            <Button size='x-small' round theme='purple' onClick={props.refresh}>
              <Refresh />
            </Button>
          </div> }
        </div>
        { props.lastRefresh && <div className='header--last-refresh'>
          Last refreshed <FormattedRelativeTime
            value={(props.lastRefresh - Date.now()) / 1000}
            updateIntervalInSeconds={10}
          />
        </div> }
      </div>
      
    </Header>
    {props.children}
  </Wrapper>
}

Tab.defaultProps = {
  onBack: undefined,
  refresh: undefined,
  lastRefresh: undefined,
  icon: null,
  title: null
}

export default Tab
