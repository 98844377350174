import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  margin-top: 48px;
  padding-bottom: 20px;
  max-width: 660px;
  margin: 0 auto;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
`

export const InfoBox = styled.div`
  padding: 50px 0px 25px 0;
  background-color: #341644;
  border-radius: 20px;
  color: #fff;
  > :nth-child(2n + 1) {
    background-color: #ffffff12;
  }
  > :nth-child(2n) {
    background-color: #ffffff0d;
  }
  > * {
    padding: 5px 10px;
  }
`
