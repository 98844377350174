import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import { PAGES, StudentPageState } from './index.state'
import StudentPageContent from './StudentPageContent'
import StudentPageFeatures from './StudentPageFeatures'
import StudentPageFriends from './StudentPageFriends'
import StudentPageSettings from './StudentPageSetting'
import Nav from './Nav'

interface StudentPageProps {
  username: string
}

const StudentPage = () => {
  const { page, student } = StudentPageState.useContainer()

  if (!page || !student || !student._id) return null

  switch (page) {
    case PAGES.CONTENT:
      return <StudentPageContent />
    case PAGES.SETTINGS:
      return <>
        <Nav />
        <StudentPageSettings />
      </>
    case PAGES.FEATURES:
      return <>
        <Nav />
        <StudentPageFeatures />
      </>
    case PAGES.FRIENDS:
      return <>
        <Nav />
        <StudentPageFriends />
      </>
    default:
      return null
  }
}

const Wrapper = styled.div`
  width: 100%;
`

export default (props: StudentPageProps) => {
  if (typeof window === 'undefined') return null
  return (
    <StudentPageState.Provider initialState={{ username: props.username }}>
      <Header />
      <Wrapper>
        <StudentPage />
      </Wrapper>
    </StudentPageState.Provider>
  )
}
