import Drawing from 'components/UI/Drawing'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../../../../../UI/Loader'
import { useDrawings } from './useDrawings'
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  margin-top: 50px;
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 30px;
`

const DrawingsList = () => {
  const { drawings, loadPage, hasMore, loadingMore } = useDrawings()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new drawings...' />}
      >
        { drawings.length === 0 && !loadingMore && <EmptyFeed>No drawings to see!</EmptyFeed>}
        <Grid>
          {drawings.map((w) => (
            <Drawing key={w._id} drawing={w} />
          ))}
        </Grid>
      </InfiniteScroll>
    </Wrapper>
  )
}

export default DrawingsList
