import useAmplitude from 'hooks/useAmplitude'
import { Button, Checkmark, Close } from 'nzk-react-components'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Input from '../../../../UI/Input'
import { StudentPageState } from '../../index.state'
import useData from '../useData'
import SelectChildren from './SelectChildren'

const Wrapper = styled.div`
  color: #aeaeaa;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px 0;
`

const Title = styled.div`
  padding: 0 12px;
  margin-bottom: 10px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 12px;
  }
  > :last-child {
    margin-right: 0px;
  }
  margin-top: 10px;
  padding: 0 24px;
`

const SectionTitle = styled.div`
  padding: 0 12px;
`

const Form = styled.div`
  > :first-child {
    width: calc(100% - 24px);
    margin-left: 12px;
  }
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Explanation = styled.p`
  font-family: 'Libre Baskerville';
  font-size: 13px;
  line-height: 15px;
`
interface IProps {
  cancel: () => void
}

const InviteFollower = (props: IProps) => {
  const { currentUser, student, updateStudent } =
    StudentPageState.useContainer()
  const { logEvent } = useAmplitude()
  const [selectedChildren, setSelectedChildren] = useState<any[]>([student._id])
  const emailRef = useRef()
  const { addAccountOwnership } = useData()
  const [formData] = useState({
    permissions: {
      details: { read: true, update: false },
      friends: { read: true, update: false },
      privacy: { read: true, update: false },
      access: { read: true, update: false },
      activities: { read: true, update: false },
      canTakeOwnership: true,
    },
  })

  const onSelectedChildrenChange = (selectedChildren: any[]) => {
    setSelectedChildren(selectedChildren)
  }

  const submit = async () => {
    if (!emailRef.current) return
    const newOwnerships = await Promise.all(
      selectedChildren.map((child) => {
        return addAccountOwnership(
          ((emailRef.current || { value: '' }).value || '').toLowerCase(),
          child._id,
          formData.permissions
        )
      })
    )

    const ownershipsFiltered = newOwnerships.filter(
      (d) => d.zookeeper._id === student._id
    )
    const newOwnership = ownershipsFiltered && ownershipsFiltered[0]
    const ownerships = [...student.accountOwnerships, newOwnership]
    updateStudent({ accountOwnerships: ownerships })
    logEvent('Dashboard: Follower Invitation Sent', { from: 'Child Settings' })
    props.cancel()
  }

  return (
    <>
      <Explanation>
        Followers are adults you trust to observe the work and progress of this
        child. Visit: Followers {'>'} Permission to control what they can see
        and do.
      </Explanation>
      <Wrapper>
        <Title>Invite Follower</Title>
        <Form>
          {/* @ts-ignore */}
          <Input placeholder="Email address" ref={emailRef} />
          <SectionTitle>To Follow:</SectionTitle>
          <SelectChildren
            childrenAccounts={currentUser.children}
            defaultSelectedChildren={selectedChildren}
            onChange={(selectedChildren) =>
              onSelectedChildrenChange(selectedChildren)
            }
          />
        </Form>
        <Actions>
          <Button round size="small" theme="red" onClick={props.cancel}>
            <Close />
          </Button>
          <Button round size="small" theme="confirm" onClick={submit}>
            <Checkmark />
          </Button>
        </Actions>
      </Wrapper>
    </>
  )
}

export default InviteFollower
