import { useLocation } from '@reach/router'
import { Link, navigate } from 'gatsby'
import { Avatar, IconButton, Button, Chart, Settings, Nzk, Menu } from 'nzk-react-components'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { AppState } from '../../../../state/AppState'
import { StudentPageState } from '../index.state'

const Wrapper = styled.div`
  background-color: #30173a;
  box-shadow: 0 6px 0 #1c0725, 0 8px 0 rgba(0, 0, 0, 0.4);
  font-family: 'Rammetto One';
  color: #fff;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-sizing: border-box;
  a {
    text-decoration: none;
  }
  > div {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    > * {
      margin-right: 10px;
    }
    > :last-child {
      margin-right: 0;
    }
  }
  > :first-child {
    justify-content: flex-start;
  }
  > :last-child {
    justify-content: flex-end;
    > * {
      position: relative;
    }
  }
  > :nth-child(2) {
    justify-content: center;
  }

  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }

  @media (max-width: 700px) {
    .mobile-only {
      display: flex;
    }
    .desktop-only {
      display: none;
    }
  }

  
`

const LeftMenuToggle = styled.div`
  @media (max-width: 700px) {
    .header-username {
      display: none;
    }
  }
`

const Header = () => {
  const { student } = StudentPageState.useContainer()
  const { rightMenuDisplayed, toggleRightMenu, toggleLeftMenu } = AppState.useContainer()
  const location = useLocation()

  const target = useMemo(() => {
    if (/settings|features|friends/.test(location.href)) {
      return {
        icon: <Link to={`/student/${student.username}`}>
          <div className='mobile-only'>
            <Button theme='purple' size='small' round>
              <Chart />
            </Button>
          </div>
          <div className='desktop-only'>
            <IconButton theme='purple' size='small' icon={<Chart />}>
              Progress
            </IconButton>
          </div>
        </Link>
      }
    }
    return {
      icon: <Link to={`/student/${student.username}?page=settings`}>
        <div className='mobile-only'>
          <Button theme='purple' size='small' round>
            <Settings />
          </Button>
        </div>
        <div className='desktop-only'>
          <IconButton theme='purple' size='small' icon={<Settings />}>
            Settings
          </IconButton>
        </div>
      </Link>
    }
  }, [location, student])

  return (
    <Wrapper>
      <LeftMenuToggle onClick={toggleLeftMenu}>
        <Avatar user={{ ...student, type: 'student' }} size="40px" />
        <div className='header-username'>{student.nickname || student.username}</div>
      </LeftMenuToggle>
      <div>
        {/* Every icon should have shared props and onClick should be one of them */}
        {/* @ts-ignore */}
        <Button theme='purple' size='small' round onClick={() => navigate('/')}>
            <Nzk />
          </Button>
      </div>
      <div>
        { target.icon }
        {!rightMenuDisplayed && (
          // @ts-ignore
          <Button theme='purple' size='small' round onClick={toggleRightMenu}>
            <Menu />
          </Button>
        )}
      </div>
    </Wrapper>
  )
}

export default Header
