import React from "react"
import Layout from '../../layouts/layout'
import StudentPage from "../../components/pages/StudentPage"
import { PageProps } from 'gatsby'

const StudentPageWrapper = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <Layout>
    <StudentPage username={props.params.username} />
  </Layout>
}

export default StudentPageWrapper
