import { Avatar, Button, IconButton, Trophy } from 'nzk-react-components'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rankFinder } from '../../../../../../utils/rankFinder'
import { ReactComponent as Recruit } from '../../../../../../assets/icons/recruit.svg'
import { ReactComponent as Pro } from '../../../../../../assets/icons/pro.svg'
import { ReactComponent as Captain } from '../../../../../../assets/icons/captain.svg'
import { ReactComponent as Hero } from '../../../../../../assets/icons/hero.svg'
import { ReactComponent as Legend } from '../../../../../../assets/icons/legend.svg'

interface IProps {
  username: string
  wordCount: number
  yearGroup: number
  countryCode: string
  avatar: { url: string }
  league?: { position: string }
  level?: number
}

const Wrapper = styled.div`
  color: #fff;
  display: inline-block;
  background-color: #341644;
  border-radius: 13px;
  padding: 30px 20px;
  min-width: 310px;
  max-width: 360px;
  width: 100%;

  > .student-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > :first-child { min-width: 60px; }
    > .student-info--infos {
      margin-left: 20px;
      > :first-child {
        font-size: 11px;
      }
      > .student-info--infos-username {
        overflow: hidden;
        white-space: nowrap;
        width: calc(360px - 20px - 60px - 25px - 20px);
        text-overflow: ellipsis;
      }
    }
  }
  > .student-ranks {
    display: flex;
    // > * { margin-right: 25px; }
    // > :last-child { margin-right: 0; }
    justify-content: space-between;
  }
`

const LabelledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :last-child {
    font-size: 9px;
    text-align: center;
  }
  > :first-child {
    margin-bottom: 0;
    text-shadow: 0px 2px #afafaf, 0px 4px rgba(0, 0, 0, 0.3);
  }
  .league-label {
    margin-top: -8px;
  }
  .rank-icon {
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 5px;
    border-radius: 50%;
    position: relative;
    > * {
      position: absolute;
      top: 10%;
      left: 10%;
      height: 80%;
      width: 80%;
    }
    > svg:first-child {
      fill: rgba(0,0,0,0.3);
      transform: translateY(2px);
    }
    > svg:nth-child(2) {
      fill: #fff;
    }
  }
`

const StudentCard = (props: IProps) => {

  const rank = useMemo(() => {
    return rankFinder(props.wordCount, props.yearGroup)
  }, [])

  const rankIcon = useMemo(() => {
    if (rank === 'Recruit') return <Recruit />
    if (rank === 'Pro') return <Pro />
    if (rank === 'Captain') return <Captain />
    if (rank === 'Hero') return <Hero />
    if (rank === 'Legend') return <Legend />
  }, [rank])

  return <Wrapper>
    <div className='student-info'>
      <Avatar user={{ avatar: props.avatar, countryCode: props.countryCode, type: 'student'}} />
      <div className='student-info--infos'>
        <div>Night Zookeeper</div>
        <div className='student-info--infos-username'>{props.username}</div>
      </div>
    </div>
    <div className='student-ranks'>
      { rank && 
        <LabelledButton>
          <Button backgroundColor='#FFC400' size='small' round>
            <div className='rank-icon'>
              {rankIcon}
              {rankIcon}
            </div>
          </Button>
          <div>{rank}</div>
        </LabelledButton>
      }
      { typeof props.level !== 'undefined' && props.level >= 0 && <LabelledButton>
        <Button backgroundColor='#8CC63F' size='small' round>{ props.level }</Button>
        <div>Level</div>
      </LabelledButton> }
      { props.league && <LabelledButton>
        <IconButton backgroundColor='#B74614' size='small' icon={<Trophy />}>
          { props.league.position }
        </IconButton> 
        <div className='league-label'>League Position</div>
      </LabelledButton>}
    </div>
  </Wrapper>
}

StudentCard.defaultProps = {
  league: null,
  level: null,
}

export default StudentCard
