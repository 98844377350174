import React from 'react'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  nbRecords: number,
  // eslint-disable-next-line react/no-unused-prop-types
  recordsThisMonth: number,
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

const NUMBERS = (props: IProps) => [
  { label: 'Total records', value: `${props.nbRecords}` },
  { label: 'Records this month', value: `${props.recordsThisMonth}` },
]

export const PRIMARY = '#A0891F'

const ReadingsCard = (props: IProps) => {
  return <NumbersCard actions={props.actions} bgColor={PRIMARY} numbers={NUMBERS(props)} loading={props.loading}  />
}

ReadingsCard.defaultProps = {
  actions: [],
  loading: false,
}

export default ReadingsCard
