import { navigate, useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

export type ITab = 'settings' | 'features' | 'friends'

export const TABS = {
  SETTINGS: 'settings' as ITab,
  FEATURES: 'features' as ITab,
  FRIENDS: 'friends' as ITab,
}

const useNav = () => {
  const location = useLocation()
  const [tab, setTab] = useState(TABS.SETTINGS)

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const tab = urlParams.get('page')
    setTab((tab as ITab) || TABS.SETTINGS)
  }, [location])

  const changeTab = (tab: ITab) => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('page', tab)
    navigate(`${location.pathname}?${urlParams.toString()}`)
  }
  return { tab, changeTab }
}

export default useNav
