import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import useCache from 'hooks/useCache'
import GET_WRITING_PAGE from './graphql/getWritingPage.graphql'
import GET_STATS from './graphql/getWritingStats.graphql'
import GET_RANGE from './graphql/getWritingRange.graphql'
import { useStudentPage } from '../../../index.state'
import useRange from '../../hooks/useRange'

interface IWriting {
  _id: string
  text: string
  title: string
  nbComments
  draft: string
  status: string
  animal: {
    _id: string
    artwork: {
      url: string
    }
  }
  zookeeper: any
  comments: {
    _id: string
    user: {
      _id: string
      name: string
      countryCode: string
      type: string
      username: string
      avatar: {
        url: string
      }
    }
    text: string
    createdAt: Date
  }[]
  createdAt: Date
}

interface WritingStatPoint {
  timestamp?: number
  nbArticles: number
  wordCount: number
}

const WritingState = () => {
  const client = useApolloClient()
  const { student } = useStudentPage()

  const [writings, setWritings] = useState<IWriting[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [loadingStats, setLoadingStats] = useState<boolean>(false)
  const [thisMonthStats, setThisMonthStats] = useState<WritingStatPoint | null>(null)
  const [overallStats, setOverallStats] = useState<WritingStatPoint | null>(null)

  const [loadingRange, setLoadingRange] = useState<boolean>(false)

  const LAST_REFRESH_CACHE_KEY = `${student.username}_writing`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  const { rangeSettings, nextMonth, previousMonth, canNext, canPrevious } = useRange({ minDate: student.createdAt })

  const [range, setRange] = useState<WritingStatPoint[]>([])

  const PAGE_SIZE = 6

  const fetchPage = async(page: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_WRITING_PAGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      errorPolicy: 'all',
      variables: {
        username: student.username,
        skip: PAGE_SIZE * (page - 1),
        limit: PAGE_SIZE
      }
    })
    return (data?.findUser?.writings || []).map(w => ({ ...w, zookeeper: student }))
  }

  const loadPage = async (page: number, refetch?: boolean) => {
    if (loadingMore) return
    setLoadingMore(true)
    const writings = await fetchPage(page, refetch)
    setLoadingMore(false)
    setHasMore(writings.length >= PAGE_SIZE)
    setWritings(w => ([ ...w, ...writings ]))
    return true
  }

  const refetchFirstPage = async (refetch?: boolean) => {
    const fetchedWritings = await fetchPage(1, refetch)
    setWritings((writings) => {
      const lastWritingMap = writings.slice(0, PAGE_SIZE).reduce((acc, writing) => {
        acc[writing._id] = writing
        return acc
      }, {})
      const { existingWriting, newWriting } = fetchedWritings.reduce((acc, writing) => {
        if (lastWritingMap[writing._id]) {
          acc.existingWriting.push(writing)
        } else {
          acc.newWriting.push(writing)
        }
        return acc
      }, { existingWriting: [], newWriting: [] })
    
      // ? console.log(`${newWriting.length} new writing and ${existingWriting.length} updated.`)
      return [
        ...newWriting,
        ...existingWriting,
        ...writings.slice(PAGE_SIZE, writings.length - 1)
      ]
    })
  }

  const loadStats = async (refetch?: boolean) => {
    setLoadingStats(true)
    const thisMonthTo = moment().startOf('day')
    const thisMonthFrom = moment().startOf('month')
  
    const { data } = await client.query({
      query: GET_STATS,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        thisMonthFrom,
        thisMonthTo,
      },
      
      errorPolicy: 'all'
    })
    if (data.findUser.writingStats) {
      setOverallStats(data.findUser.writingStats.stats)
      setThisMonthStats(data.findUser.writingStats.thisMonthStats)
    }
    setLoadingStats(false)
  }

  const loadRange = useCallback(async (refetch?: boolean) => {
    setLoadingRange(true)
    const { data } = await client.query({
      query: GET_RANGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from: rangeSettings.from,
        to: rangeSettings.to,
        by: rangeSettings.by
      },
      errorPolicy: 'all'
    })
    if (data.findUser.writingStats) {
      setRange(data.findUser.writingStats.range)
    }
    setLoadingRange(false)
  }, [rangeSettings])

  useEffect(() => {
    if (rangeSettings.updated) {
      loadRange()
    }
  }, [rangeSettings])

  const refresh = useMemo(() => {
    if (canRefresh) {
      return () => {
        loadRange(true)
        loadStats(true)
        refetchFirstPage(true)
        update(Date.now())
      }
    }
    return undefined
  }, [canRefresh])

  return {
    // Feed
    loadPage,
    loadingMore,
    hasMore: !loadingMore && hasMore,
    writings,
    // Stats
    loadStats,
    loadingStats,
    thisMonthStats,
    overallStats,
    // Range
    loadingRange,
    rangeSettings,
    rangePreviousMonth: previousMonth,
    rangeNextMonth: nextMonth,
    canNextMonth: canNext,
    canPreviousMonth: canPrevious,
    loadRange,
    range,
    // Refresh
    refresh,
    lastRefresh,
  }
}

const WritingContainer = createContainer(WritingState)
export const WritingProvider = WritingContainer.Provider
export const useWriting = WritingContainer.useContainer