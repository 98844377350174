import React from 'react'
import styled from 'styled-components'
import RatioBox from '../RatioBox'

const Wrapper = styled.div`
  border-radius: 25px;
  overflow: hidden;
  background-color: #341644;
  box-shadow: 0 6px 0 rgba(0,0,0,0.3);
  margin-bottom: 6px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #7C1120;
  box-shadow: 0 6px 0 rgba(0,0,0,0.3);
  border-radius: 25px;
`

const Title = styled.div`
  color: #ffffff;
  padding: 8px 15px 30px 15px;
`

interface IProps {
  type: string
}

const configs = {
  'quiz': {
    title: 'Will\'s Quiz Quest',
    image: '/images/curriculum-challenges/quiz-quests.jpg',
  },
  'jumbled-word': {
    title: 'Eek\'s Code',
    image: '/images/curriculum-challenges/eeks-word-code.jpg',
  },
  'jumbled-sentence': {
    title: 'Sea Lion General\'s Sentences',
    image: '/images/curriculum-challenges/sea-lion-sentences.jpg',
  },
  'matching': {
    title: 'Maji\'s Matching',
    image: '/images/curriculum-challenges/maji-matches.jpg',
  },
  'grouping': {
    title: 'Grudge\'s Grouping',
    image: '/images/curriculum-challenges/grudge-groups.jpg',
  },
  'short-writing': {
    title: 'Bertie\'s Bumble',
    image: '/images/curriculum-challenges/berties-bumbles.jpg',
  },
  'missing-word': {
    title: 'Beaver Blanks',
    image: '/images/curriculum-challenges/beavers-blank.jpg',
  },
  'missing-word-typing': {
    title: 'Beaver Blanks',
    image: '/images/curriculum-challenges/beavers-blank.jpg',
  },
  'reading-comprehension': {
    title: 'Sam\'s Spies',
    image: '/images/curriculum-challenges/sam-spies.jpg',
  },
  'paragraphs': {
    title: 'Penguin\'s Paragraphs',
    image: '/images/curriculum-challenges/penguins-paragraphs.jpg',
  },
  'definitions': {
    title: 'Definitions',
    image: '/images/curriculum-challenges/deep-definitions.jpg',
  },
  'namings': {
    title: 'Ninja\'s Namings',
    image: '/images/curriculum-challenges/ninja-naming.jpg',
  },
  'word-search': {
    title: 'Star Search',
    image: '/images/curriculum-challenges/star-search.jpg',
  },
  'grouping2': {
    title: 'Guglum\'s Grouping',
    image: '/images/curriculum-challenges/greedy-guglums.jpg',
  },
  'gallery': {
    title: 'Vincent\'s Gallery',
    image: '/images/curriculum-challenges/vincents-gallery.jpg',
  },
}

const ChallengeTypeHero = (props: IProps) => {
  const config = configs[props.type] || { title: 'Unknown Challenge', image: undefined }

  return <Wrapper>
    <RatioBox ratio='16:9'>
      <Image src={config.image} />
    </RatioBox>
    <Title>
      { config.title }
    </Title>
  </Wrapper>
}

export default ChallengeTypeHero
