import React from 'react'
import styled from 'styled-components'
import Loader from 'components/UI/Loader'

const Wrapper = styled.div`
  color: #fff;
  display: inline-block;
  border-radius: 13px;
  padding: 0px 8px 27px 8px;
  border-radius: 20px;
  min-width: 310px;
  max-width: 360px;
  width: 100%;
  background-color: #7C1120;

  > .info {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Libre Baskerville';
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 11px;
`

const Content = styled.table``

const Score = styled.tr`
  text-transform: capitalize;
  > :first-child {
    font-size: 12px;
    text-align: right;
    padding-right: 10px;
    text-transform: capitalize;
  }
`

const Bar = styled.td`
  width: 100%;
  height: 24px;
  position: relative;
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: red;
    text-align: right;
    line-height: 24px;
    padding-right: 6px;
    font-size: 14px;
    width: 0;
    transition: width 0.4s ease-in-out;
  }
`

interface IProps {
  loading?: boolean
  scores: {
    nbChallengesCompleted: number,
    score: number,
    category: {
      _id: string,
      title: string
    }
  }[]
}

const ChallengeAccuracyScoreCard = (props: IProps) => {
  const getColor = (score) => {
    if (score > 0.6) return '#579800'
    if (score > 0.4) return '#EB7700'
    return '#FB263B'
  }

  return <Wrapper>
    <Title>Accuracy Score</Title>
    {
      props.loading
        ? <Loader color='#ffffff55' minHeight={100} size={60} />
        : <>
          { props.scores.length === 0 && <p className='info'>Average accuracy score will display here.</p>}
          <Content>
            <tbody>
              { props.scores.map(score => {
                return <Score key={score.category._id}>
                  <td>{score.category.title?.toLowerCase()}</td>
                  <Bar>
                    <div style={{ backgroundColor: getColor(score.score), width: `${score.score * 100}%` }}>
                      {Math.round(score.score * 100)}%
                    </div>
                  </Bar>
                </Score>
              }) }
            </tbody>
          </Content>
        </> 
    }
  </Wrapper>
}

ChallengeAccuracyScoreCard.defaultProps = {
  loading: false
}

export default ChallengeAccuracyScoreCard
