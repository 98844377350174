import { StarTutoringZookeeperNoLayout } from 'components/pages/StarTutoring/pages/StarTutoringZookeeper'
import { useStudentPage } from 'components/pages/StudentPage/index.state'
import React from 'react'

const AdvancedTutoring = () => {
  const { username } = useStudentPage()

  if (!username) return null
  return <StarTutoringZookeeperNoLayout username={username} />
}

export default AdvancedTutoring
