import moment from 'moment'
import { ArrowLeft, ArrowRight, Button } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import { IRangeSettings } from '../hooks/useRange'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  > .chart-settings--dates {
    margin: 0px 15px;
  }
`

interface IProps {
  previous: () => void
  next: () => void
  canPrevious?: boolean
  canNext?: boolean
  settings: IRangeSettings
}

const ChartSettings = (props: IProps) => {
  return <Wrapper>
    <Button size='x-small' round theme='primary' disabled={!props.canPrevious} onClick={() => props.canPrevious && props.previous()}><ArrowLeft /></Button>
    <div className='chart-settings--dates'>
      { moment(props.settings.from).format('MMM YYYY') }
    </div>
    <Button size='x-small' round theme='primary' disabled={!props.canNext} onClick={() => props.canNext && props.next()}><ArrowRight /></Button>
  </Wrapper>
}

ChartSettings.defaultProps = {
  canPrevious: true,
  canNext: true
}

export default ChartSettings
