import { useApolloClient } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../../state/CurrentUserState'
import CancelIcon from '../../../UI/Icons/Cancel'
import TickIcon from '../../../UI/Icons/Tick'
import Select from '../../../UI/Select'
import { getYearGroup } from '../../AccountSettings/helpers'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
// @ts-ignore
import UPDATE_YEAR_GROUP from './updateYearGroup.graphql'

const UK_YEAR_GROUPS = [
  { value: 0, label: 'Year R (Age 4-5)' },
  { value: 1, label: 'Year 1 (Age 5-6)' },
  { value: 2, label: 'Year 2 (Age 6-7)' },
  { value: 3, label: 'Year 3 (Age 7-8)' },
  { value: 4, label: 'Year 4 (Age 8-9)' },
  { value: 5, label: 'Year 5 (Age 9-10)' },
  { value: 6, label: 'Year 6 (Age 10-11)' },
  { value: 7, label: 'Year 7+ (Age 12+)' },
]

const US_YEAR_GROUPS = [
  { value: 0, label: 'Pre-K (Age 4-5)' },
  { value: 1, label: 'Kindergarten (Age 5-6)' },
  { value: 2, label: '1st Grade (Age 6-7)' },
  { value: 3, label: '2nd Grade (Age 7-8)' },
  { value: 4, label: '3rd Grade (Age 8-9)' },
  { value: 5, label: '4th Grade (Age 9-10)' },
  { value: 6, label: '5th Grade (Age 10-11)' },
  { value: 7, label: '6th Grade (Age 12+)' },
]

const Wrapper = styled(EditorElement)``

const Info = styled.div`
  font-size: 15px;
  font-family: 'Libre Bakerville';
  margin-top: 10px;
  color: #afafaf;
`

interface ChangeYearGroupProps {
  error: Function
  success: Function
}

const ChangeYearGroup = (props: ChangeYearGroupProps) => {
  const { student, updateStudent, permissions } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const client = useApolloClient()
  const [yearGroup, setYearGroup] = useState(student.yearGroup)
  const { currentUser } = CurrentUserState.useContainer()

  useEffect(() => {
    setYearGroup(student.yearGroup)
  }, [student])

  const YEAR_GROUPS =
    currentUser.countryCode === 'GB' ? UK_YEAR_GROUPS : US_YEAR_GROUPS

  const save = async () => {
    if (error) return
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_YEAR_GROUP,
        variables: {
          id: student._id,
          yearGroup,
        },
      })
      if (data && data.updateUser._id) {
        updateStudent({ yearGroup })
        props.success(`Year group successfully changed to ${yearGroup}`)
        setEnabled(false)
      }
    } catch (err) {
      setError('An error has occured.')
      props.error('An error has occured.')
    }
  }

  const realYearGroup = useMemo(() => {
    if (student.birthYear && student.birthMonth) {
      return getYearGroup(student.birthMonth, student.birthYear)
    }
    return student.yearGroup
  }, [student])

  const handleEnabled = () => {
    props.error('')
    props.success('')
    setEnabled(!enabled)
  }

  const yearGroupLabelFor = (yearGroup) => {
    const label = YEAR_GROUPS[yearGroup] ? YEAR_GROUPS[yearGroup].label : ''
    const diff =
      Math.abs(realYearGroup - yearGroup) > 0
        ? `${Math.abs(realYearGroup - yearGroup)}: `
        : ''
    if (realYearGroup - yearGroup > 0) {
      return `-${diff}${label}`
    }
    if (realYearGroup - yearGroup === 0) {
      return `${label}`
    }
    return `+${diff}${label}`
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <div>Level of difficulty</div>
          <p>{yearGroupLabelFor(student.yearGroup)}</p>
        </div>
        {permissions.details.update && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper backgroundColor="#FFFFFF" textColor="#000">
      <div>
        <div>Level of difficulty</div>
        <Select
          options={YEAR_GROUPS.map((y) => {
            return {
              value: y.value,
              label: yearGroupLabelFor(y.value),
            }
          })}
          value={yearGroup}
          onChange={setYearGroup}
        />
        <Info>Adjust to make it easier or harder.</Info>
        <EditingActions>
          <CancelIcon
            size={32}
            color="#EC1A18"
            onClick={() => {
              handleEnabled()
            }}
          />
          <TickIcon size={32} color="#8CC63F" onClick={save} />
        </EditingActions>
      </div>
    </Wrapper>
  )
}

export default ChangeYearGroup
