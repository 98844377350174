import React from 'react'
import styled from 'styled-components'
import Book from 'components/UI/Book'
import Loader from  'components/UI/Loader'

interface IProps {
  books: {
    _id: string
    title: string
    imageUrl: string
  }[]
  loading?: boolean
}

export const PRIMARY = '#A0891F'

const Wrapper = styled.div`
  color: #fff;
  display: inline-block;
  border-radius: 13px;
  padding: 20px 0px 10px 0;
  min-width: 310px;
  max-width: 360px;
  width: 100%;
  background-color: ${PRIMARY};

`

const Content = styled.div`
  background-color: rgba(255, 255, 255, 0.07);
  padding: 10px 15px 0 15px;
  > :first-child {
    font-size: 12px;
    margin-bottom: 10px;
  }
  > .info {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Libre Baskerville';
  }
`

const Books = styled.div`
  display: flex;
  padding-bottom: 10px;
  > * { margin-right: 10px; min-width: 80px; }
  overflow: auto;
`

const BooksCard = (props: IProps) => {
  return <Wrapper>
    <Content>
      <div>Books Read</div>
      {
        props.loading
          ? <Loader color='#ffffff55' minHeight={100} size={60} />
          : <>
            {
              props.books.length === 0 && <p className='info'>Books will appear here when added in Reading Light.</p>
            }
            <Books>
              { props.books.map(b => <Book key={b._id} book={b} width={80} />)}
            </Books>
          </>
      }
    </Content>
  </Wrapper>
}

BooksCard.defaultProps = {
  loading: false,
}

export default BooksCard
