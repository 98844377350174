import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import Checkbox from 'components/UI/Checkbox'
import Toggle from 'components/UI/Toggle'
import { Avatar, Button, Close } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ConfirmTransfer from '../ConfirmTransfer'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  box-shadow: 0 -4px 0 #4B1B68, 0 4px 0 #1B0525, 0 8px 0 rgba(0,0,0,0.4);
  color: #fff;
  border-radius: 20px;
  max-width: 550px;
  padding: 20px 0px;
  font-family: "Libre Baskerville";
  p {
    padding: 0 20px;
  }
  @media (min-width: 1040px) {
    max-width: 100%;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
`

const Title = styled.div`
  font-family: "Rammetto One";
  text-shadow: 0px 5px #afafaf, 0px 10px #120219;
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
`

const Content = styled.div`
  background-color: #ffffff05;
  padding: 10px 15px 20px 15px;

`

const Description = styled.div`
  padding: 0 15px;
  margin-bottom: 20px;
`

const Section = styled.div`
  padding: 0 15px;
`

const User = styled.div`
  display: flex;
  > :first-child {
    margin-right: 12px;
  }
`

const CheckboxField = styled.div`
  display: flex;
  > :first-child {
    display: inline-flex;
    margin-right: 20px;
  }
  > :nth-child(2) {
    padding-top: 5px;
  }
  margin-bottom: 15px;
`

const Ownerships = styled.div`
  margin-top: 30px;
  > * { margin-bottom: 8px; }
  > :last-child { margin-bottom: 0; }
`

const Ownership = styled.div`
  display: flex;
  justify-content: space-between;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

interface IProps {
  dismiss: () => void,
  zookeeper: {
    nickname?: string,
    username: string,
    avatar: { url: string },
    accountOwnerships: IAccountOwnership[],
  }
}

interface ITransferData {
  selectedOwnerships: IAccountOwnership[],
  takesEffect: 'IMMEDIATE' | 'PERIOD_END'
}

const TransferOwnershipModal = (props: IProps) => {
  const { dismiss, zookeeper } = props
  const [data, setData] = useState<ITransferData>({
    selectedOwnerships: [],
    takesEffect: 'IMMEDIATE'
  })
  const [confirm, setConfirm] = useState(false)

  const updateData = (payload) => {
    setData(d => ({ ...d, ...payload }))
  }

  useEffect(() => {
    if (data.selectedOwnerships.length > 1 && data.takesEffect === 'IMMEDIATE') {
      updateData({ takesEffect: 'PERIOD_END' })
    }
  }, [data])

  const toggleOwnership = (ownership) => {
    setData(d => {
      const selectedOwnerships = [...d.selectedOwnerships]
      if (selectedOwnerships.map(d => d._id).indexOf(ownership._id) >= 0) {
        return { ...d, selectedOwnerships: selectedOwnerships.filter(d => d._id !== ownership._id) }
      }
      return { ...d, selectedOwnerships: [...selectedOwnerships, ownership] }
    })
  }

  const onNext = () => {
    if (data.selectedOwnerships.length === 0) return
    setConfirm(true)
  }

  return <Overlay onClick={dismiss}>
    { !confirm && <Wrapper onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
    }}>
      <CloseButton onClick={props.dismiss}>
        <Button theme="red" size="small" round>
          <Close />
        </Button>
      </CloseButton>
      <Title>
        Transfer Ownership
      </Title>
      <Description>
        Who would  you like to invite to take ownership for this child:
      </Description>
      <Content>
        <User>
          <Avatar user={zookeeper} size='110px' />
          <div>
            <div>{zookeeper.nickname}</div>
            <div>{zookeeper.username}</div>
          </div>
        </User>
        <Ownerships>
          { (zookeeper.accountOwnerships || []).filter(d => d.status === 'ACCEPTED').map(d => <Ownership key={d._id}>
            <div>
              {d.user ? d.user.name : d.email }
            </div>
            <div>
              <Toggle selected={data.selectedOwnerships.map(o => o._id).indexOf(d._id) >= 0} onChange={() => toggleOwnership(d)} />
            </div>
          </Ownership>)}
        </Ownerships>
      </Content>
      <Section>
        <p>When should the new owner(s) be able to take control:</p>
        <CheckboxField>
          <Checkbox selected={data.takesEffect === 'IMMEDIATE'} onChange={() => updateData({ takesEffect: 'IMMEDIATE' })} />
          <div>Immediately</div>
        </CheckboxField>
        <CheckboxField>
          <Checkbox selected={data.takesEffect === 'PERIOD_END'} onChange={() => updateData({ takesEffect: 'PERIOD_END' })} />
          <div>When my subscription expires</div>
        </CheckboxField>
      </Section>
      <Actions>
        <Button size='small' theme='primary' onClick={onNext} disabled={data.selectedOwnerships.length === 0}>Next</Button>
      </Actions>
    </Wrapper> }
    { confirm && <ConfirmTransfer data={data} zookeeper={zookeeper} dismiss={props.dismiss} /> }
  </Overlay>
}

export default TransferOwnershipModal
