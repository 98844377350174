import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'

const LEARNING_AREAS = {
  spelling: { id: 'spelling', color: '#C6BC3F', value: 'Spelling' },
  vocabulary: { id: 'vocabulary', color: '#C6BC3F', value: 'Vocabulary' },
  grammar: { id: 'grammar', color: '#C6BC3F', value: 'Grammar' },
  typing: { id: 'vocabutypinglary', color: '#C6BC3F', value: 'Typing' },
}

export const GAMES = {
  'word-hunt': {
    normalizedId: 'wordHunt',
    thumbnail: '/images/games/word-hunt.jpg',
    learningTopic: 'Identifying correctly spelled words',
    learningAreas: [LEARNING_AREAS.spelling]
  },
  'jumble-words': {
    normalizedId: 'jumbleWords',
    thumbnail: '/images/games/jumble-words.jpg',
    learningTopic: 'Structuring sentences correctly',
    learningAreas: [LEARNING_AREAS.grammar]
  },
  'torch-type': {
    normalizedId: 'torchType',
    thumbnail: '/images/games/torch-type.jpg',
    learningTopic: 'Increasing typing speed and stamina',
    learningAreas: [LEARNING_AREAS.typing]
  },
  'word-climb': {
    normalizedId: 'wordClimb',
    thumbnail: '/images/games/word-climb.jpg',
    learningTopic: 'Identifiying common synonyms',
    learningAreas: [LEARNING_AREAS.vocabulary]
  },
  'word-hop': {
    normalizedId: 'wordHop',
    thumbnail: '/images/games/word-hop.jpg',
    learningTopic: 'Identifying correctly spelled words',
    learningAreas: [LEARNING_AREAS.spelling]
  },
  'word-pairs': {
    normalizedId: 'wordPairs',
    thumbnail: '/images/games/word-pairs.png',
    learningTopic: 'Identifiying common synonyms',
    learningAreas: [LEARNING_AREAS.vocabulary]
  },
  'word-wings': {
    normalizedId: 'wordWings',
    thumbnail: '/images/games/word-wings.jpg',
    learningTopic: 'Spelling common words correctly',
    learningAreas: [LEARNING_AREAS.spelling]
  },
  'word-woods': {
    normalizedId: 'wordWoods',
    thumbnail: '/images/games/word-woods.jpg',
    learningTopic: 'Broadening vocabulary',
    learningAreas: [LEARNING_AREAS.vocabulary]
  },
  'word-wrecker': {
    normalizedId: 'wordWrecker',
    thumbnail: '/images/games/word-wrecker.jpg',
    learningTopic: 'Using clues to spell words',
    learningAreas: [LEARNING_AREAS.spelling]
  }
}

interface IProps {
  score: {
    _id: string
    gameId: string
    score: string
    createdAt: string
    leaderboardPointsEarned: number
  }
  bestScore?: number | null
}

const Wrapper = styled.div`
  font-family: 'Libre Baskerville';
  font-size: 14px;
  > .score--date {
    margin-bottom: 10px;
  }
`

const Card = styled.div`
  background-color: #B70620;
  padding: 20px 0px;
  border-radius: 20px;
`

const Content = styled.div`
  font-family: 'Rammetto One';
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 10px;
  > * { margin-bottom: 20px; }
  > :last-child { margin-bottom: 0; }
  section {
    font-size: 18px;
    line-height: 30px;
    > :first-child {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 8px;
    }
  }
`

const Thumbnail = styled.div`
  height: 240px;
  width: 100%;
  background-color: #ebebeb;
  border-radius: 20px;
  background-image: ${(props: { src: string }) => `url("${props.src}")`};
  background-size: cover;
  background-position: center;
`

const Scores = styled.div`
  display: flex;
  justify-content: space-between;
`

const ScoreLabel = styled.div`
  font-size: 18px;
  line-height: 30px;
  > :first-child {
    font-size: 13px;
    line-height: 21px;
    margin-bottom: 8px;
  }
  > :last-child {
    font-size: 25px;
  }
`

const LearningAreas = styled.div`
  display: flex;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const LearningArea = styled.div`
  border-radius: 50px;
  padding: 0 30px;
`

const Score = (props: IProps) => {
  if (!props.score) return null

  const GAME = GAMES[props.score.gameId]

  return <Wrapper>
    { props.score.createdAt && <div className='score--date'><FormattedDate
        value={props.score.createdAt}
        month="long"
        year="numeric"
        day="numeric"
        hour12
        hour='numeric'
        minute='numeric'
      /></div>}
    <Card>
      <Content>
        { GAME && <Thumbnail src={GAME.thumbnail} /> }
        { GAME?.learningTopic && <section>
          <div>Learning topic:</div>
          <div>{GAME.learningTopic}</div>
        </section> }
        { GAME?.learningAreas && <section>
          <div>Learning area:</div>
          <LearningAreas>
            { GAME.learningAreas.map((a) => <LearningArea key={a.id} style={{ backgroundColor: a.color }}>
              { a.value }
            </LearningArea>)}
          </LearningAreas>
        </section> }
        <Scores>
          <ScoreLabel>
            <div>Score:</div>
            <div>{props.score.score}</div>
          </ScoreLabel>
          { props.bestScore && <ScoreLabel>
            <div>Personal best:</div>
            <div>{props.bestScore}</div>
          </ScoreLabel> }
        </Scores>
      </Content>
    </Card>
  </Wrapper>
}

Score.defaultProps = {
  bestScore: null
}

export default Score
