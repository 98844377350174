import React from 'react'
import { FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../../../../../theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Title = styled.div`
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: bold;
`

export const Progress = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
  background-color: ${colors.gray};
  border-radius: 15px;
  max-width: 340px;
  overflow: hidden;
  ::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.percent * 100}%;
    content: '';
    background-color: #61237D;
  }
  > :first-child {
    position: absolute;
    color: white;
    left: calc(${props => props.percent * 100}% - 5px);
    transform: translateX(-100%);
    height: 100%;
    line-height: 30px;
    font-weight: bold;
    z-index: 1;
  }
`

const LearningGoalProgress = ({ title, nbCompleted, nbTodo, score }) => {
  return <Wrapper>
    <Title>{title}</Title>
    <div>Accuracy: <FormattedNumber value={score * 100} maximumFractionDigits={1} />%</div>
    <Progress percent={nbCompleted / (nbCompleted + nbTodo)} />
  </Wrapper>
}

LearningGoalProgress.propTypes = {

}

LearningGoalProgress.defaultProps = {

}

export default LearningGoalProgress
