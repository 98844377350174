import React, { useMemo } from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import Book from '../Book'

interface IProps {
  bookRecord: {
    _id: string
    createdAt: Date
    book: {
      _id: string
      title: string
      imageUrl: string
    }
    text: string
    timeSpent: number
  }
}

const Wrapper = styled.div`
  font-family: 'Libre Baskerville';
  font-size: 14px;
  > .record--date {
    margin-bottom: 10px;
  }
`

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 5px 0 #afafaf;
`
const Content = styled.div`
  color: #3C3C3B;
  > .record-book--title {
    font-family: 'Rammetto One';
    font-size: 20px;
    margin-bottom: 10px;
  }
  > .record-book--time {
    font-family: 'Rammetto One';
    font-size: 12px;
    margin-bottom: 10px;
  }
  > .record-content {
    display: flex;
    > .record-content--book {
      margin-right: 8px;
    }
  }
`

const BookRecord = (props: IProps) => {
  if (!props.bookRecord) return null

  const readingTime = useMemo(() => {
    const minutes = props.bookRecord.timeSpent % 60
    const hours = Math.floor(props.bookRecord.timeSpent / 60)
    if (hours > 0 && minutes > 0) {
      return `Reading time: ${hours} hours and ${minutes} minutes`
    }
    if (hours > 0) {
      return `Reading time: ${hours} hours`
    }
    return `Reading time: ${minutes} minutes`
  }, [])

  return <Wrapper>
    { props.bookRecord.createdAt && <div className='record--date'><FormattedDate
        value={props.bookRecord.createdAt}
        month="long"
        year="numeric"
        day="numeric"
        hour12
        hour='numeric'
        minute='numeric'
      /></div>}
    <Card>
      <Content>
        <div className='record-book--title'>
          { props.bookRecord.book.title }
        </div>
        <div className='record-book--time'>
          {readingTime}
        </div>
        <div className='record-content'>
          <div className='record-content--book'>
            <Book book={props.bookRecord.book} width={80} />
          </div>
          <div className='record-content--text'>{props.bookRecord.text}</div>
        </div>
      </Content>
    </Card>
  </Wrapper>
}

export default BookRecord
