import React from 'react'
import styled from 'styled-components'
import Loader from '../../../../UI/Loader'
import LearningGoals from './LearningGoals'
import useCurriculum from './useCurriculum'

const Wrapper = styled.div`
  padding: 20px 20px;
  > * {
    margin-bottom: 30px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
`

const BigNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    margin: 30px 0 20px 0;
    font-size: 80px;
    color: #fff;
    font-family: 'Rammetto One', cursive;
  }
  > :nth-child(2) {
    font-size: 21px;
    margin-top: 10px;
  }
  > :nth-child(3) {
    font-size: 12px;
    font-style: italic;
  }
`

const Curriculum = (props) => {
  const { student, loading, nbChallengesLastWeek } = useCurriculum()

  if (loading) return <Wrapper><Loader /></Wrapper>
  if (!student.stats) return null

  return <Wrapper>
    <BigNumber>
      <div>{nbChallengesLastWeek}</div>
      <div>Challenges completed</div>
      <div>Data refreshes every day</div>
    </BigNumber>
    <LearningGoals />
  </Wrapper>
}

Curriculum.propTypes = {

}

Curriculum.defaultProps = {

}

export default Curriculum
