import { useApolloClient } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import CancelIcon from '../../../UI/Icons/Cancel'
import TickIcon from '../../../UI/Icons/Tick'
import Select from '../../../UI/Select'
import { getYearGroup } from '../../AccountSettings/helpers'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
// @ts-ignore
import UPDATE_AGE from './updateAge.graphql'

const Wrapper = styled(EditorElement)``

const Cancel = styled(CancelIcon)``

const Tick = styled(TickIcon)``

const BirthSection = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    width: 100%;
  }
  > :first-child {
    margin-right: 10px;
  }
`

interface ChangeAgeProps {
  error: Function
  success: Function
}

const MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

const ChangeAge = (props: ChangeAgeProps) => {
  const { student, updateStudent, permissions } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const client = useApolloClient()
  const [year, setYear] = useState<undefined | number>(student.birthYear)
  const [month, setMonth] = useState<undefined | number>(student.birthMonth)

  const years = useMemo(() => {
    const year = new Date().getFullYear()
    return new Array(15).fill(null).map((_, i) => {
      return { value: year - 15 + i, label: `${year - 15 + i}` }
    })
  }, [])

  useEffect(() => {
    setYear(student.birthYear)
    setMonth(student.birthMonth)
  }, [student])

  const save = async () => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_AGE,
        variables: {
          id: student._id,
          month,
          year,
          yearGroup: getYearGroup(month, year),
        },
      })
      if (data && data.updateUser._id) {
        updateStudent({
          birthYear: year,
          birthMonth: month,
          yearGroup: data.updateUser.yearGroup,
        })
        props.success('Age successfully changed')
        setEnabled(false)
      }
    } catch (err) {
      props.error('An error has occured.')
    }
  }

  const monthLabel = useMemo(() => {
    const monthEl = MONTHS.filter(({ value }) => month === value)
    if (monthEl && monthEl[0]) {
      return monthEl[0].label
    }
    return ''
  }, [month])

  const handleEnabled = () => {
    props.error('')
    props.success('')
    setEnabled(!enabled)
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <div>Birth Month & Year</div>
          {monthLabel || student.birthYear ? (
            <p>
              {monthLabel} {student.birthYear}
            </p>
          ) : (
            <p>Not set</p>
          )}
        </div>
        {permissions.details.update && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper backgroundColor="#FFFFFF" textColor="#AFAFAF">
      <div>
        <div>Birth Month & Year</div>
        <BirthSection>
          <Select
            options={MONTHS}
            value={month || student.birthMonth}
            onChange={(value) => {
              setMonth(value)
            }}
          />
          <Select
            options={years}
            value={year || student.birthYear}
            onChange={(value) => {
              setYear(value)
            }}
          />
        </BirthSection>
        <EditingActions>
          <Cancel
            size={32}
            color="#EC1A18"
            onClick={() => {
              handleEnabled()
            }}
          />
          <Tick size={32} color="#8CC63F" onClick={save} />
        </EditingActions>
      </div>
    </Wrapper>
  )
}

export default ChangeAge
