import React from 'react'
import Drawing from '../Drawing'

interface IProps {
  animal: {
    _id: string
    createdAt: Date | null,
    name: string | null,
    artwork: { url: string }
  }
}

const Animal = (props: IProps) => {
  if (!props.animal) return null

  return <Drawing
    drawing={{
      _id: props.animal._id,
      artwork: { url: props.animal.artwork.url },
      title: props.animal.name,
      createdAt: props.animal.createdAt
    }}
  />
}

export default Animal
