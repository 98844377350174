import { Avatar, Button } from 'nzk-react-components'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as TrashIcon } from '../../../../../assets/images/icons/trash.svg'
import Input from '../../../../UI/Input'
import { useFriendsState } from '../index.state'
import Box from './Box'

const Content = styled.div`
  color: #fff;
  padding: 5px 15px;
  padding-bottom: 30px;
`

const EmptyMessage = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 20px;
  }
  > :nth-child(2) {
    max-width: 170px;
  }
  margin-bottom: 15px;
`

const Users = styled.div`
  position: relative;
  min-height: 110px;
  max-width: 960px;
  padding-bottom: 20px;
  display: flex;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff55;
    border-radius: 12pt;
  }
`

const User = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Libre Baskerville';
  > * {
    margin-bottom: 10px;
  }
  > :nth-child(2) {
    position: absolute;
    top: 0px;
    right: 50%;
    transform: translateX(43px);
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Followers = () => {
  const [searchValue, setSearchValue] = useState('')
  const { followers, removeFollower } = useFriendsState()

  const displayedUsers = useMemo(() => {
    if (searchValue && searchValue.length >= 3) {
      return followers.filter(({ username }) =>
        new RegExp(searchValue).test(username)
      )
    }
    return followers
  }, [searchValue, followers])

  return (
    <Box width="100%">
      <Content>
        <Title>
          <div>Followers ({followers.length})</div>
          {followers.length > 0 && (
            <Input
              placeholder="Search"
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
        </Title>
        <Users>
          {followers.length === 0 && (
            <EmptyMessage>This child has no followers yet.</EmptyMessage>
          )}
          {displayedUsers.map((user) => (
            <User key={user._id}>
              <Avatar user={user} size="80px" />
              <Button
                height={35}
                backgroundColor="#f00"
                onClick={() => removeFollower(user._id)}
                round
              >
                <TrashIcon />
              </Button>
              <div>{user.username}</div>
            </User>
          ))}
        </Users>
      </Content>
    </Box>
  )
}

export default Followers
