import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { AdvancedTutoring, Button, Challenges, Lessons, Task, Writing } from 'nzk-react-components'
import { CONTENT_TABS, StudentPageState } from '../../index.state'

const Wrapper = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  position: fixed;
  z-index: 10;
  bottom: 0;
  @media (min-width: 1040px) {
    position: relative;
    bottom: initial;
  }
  background-color: #341644;
`

const Tab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
  align-items: center;
  height: 100%;
  cursor: pointer;
  user-select: none;
  line-height: 10px;
  > :first-child {
    position: relative !important;
    margin-bottom: 5px;
  }
  p {
    font-size: 10px;
    text-align: center;
  }
  ${(props: { selected: boolean }) => css`
    color: ${props.selected ? '#FFED00' : '#FFFFFF'};
    background-color: ${props.selected ? '#FFFFFF12' : 'transparent'};
  `}
`

const Nav = () => {
  const { tab, switchTab, student } = StudentPageState.useContainer()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const onSetTab = (_tab) => {
    if (tab === _tab) {
      return setMobileMenuOpen(!mobileMenuOpen)
    }
    switchTab(_tab)
    setMobileMenuOpen(!mobileMenuOpen)
    return null
  }

  return (
    <Wrapper>
      <Tab
        selected={tab === CONTENT_TABS.SUMMARY}
        onClick={() => onSetTab(CONTENT_TABS.SUMMARY)}
      >
        <Button round size='small' backgroundColor='#691CA1'>
          <Task />
        </Button>
        <p>Summary</p>
      </Tab>
      <Tab
        selected={tab === CONTENT_TABS.WRITINGS}
        onClick={() => onSetTab(CONTENT_TABS.WRITINGS)}
      >
        <Button round size='small' backgroundColor='#99D131'>
          <Writing />
        </Button>
        <p>Writing</p>
      </Tab>
      <Tab
        selected={tab === CONTENT_TABS.CHALLENGES}
        onClick={() => onSetTab(CONTENT_TABS.CHALLENGES)}
      >
        <Button round size='small' backgroundColor='#B70620'>
          <Challenges />
        </Button>
        <p>Challenges</p>
      </Tab>
      <Tab
        selected={tab === CONTENT_TABS.LESSONS}
        onClick={() => onSetTab(CONTENT_TABS.LESSONS)}
      >
        <Button round size='small' backgroundColor='#28348A'>
          <Lessons />
        </Button>
        <p>Lessons</p>
      </Tab>
      { student?.starTutoring?._id && <Tab
        selected={tab === CONTENT_TABS.ADVANCED_TUTORING}
        onClick={() => onSetTab(CONTENT_TABS.ADVANCED_TUTORING)}
      >
        <Button round size='small' theme='purple'>
          <AdvancedTutoring />
        </Button>
        <p>Adv. Tutoring</p>
      </Tab> }
      { /* <Tab
        selected={tab === CONTENT_TABS.ANIMALS}
        onClick={() => onSetTab(CONTENT_TABS.ANIMALS)}
      >
        <Icon name='my-zoo' size='40px' />
        <p>Animals</p>
      </Tab> */ }
      { /* <Tab
        selected={tab === CONTENT_TABS.DRAWINGS}
        onClick={() => onSetTab(CONTENT_TABS.DRAWINGS)}
      >
        <Drawings color="#CC611D" size={40} />
        <p>Drawings</p>
      </Tab>
      <Tab
        selected={tab === CONTENT_TABS.READINGS}
        onClick={() => onSetTab(CONTENT_TABS.READINGS)}
      >
        <Reading color="#FFF23D" size={40} />
        <p>Reading</p>
      </Tab> */ }
    </Wrapper>
  )
}

Nav.propTypes = {}

Nav.defaultProps = {}

export default Nav
