import useConfirmModal from 'hooks/useConfirmModal'
import { Button, Trash } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { AppState } from '../../../../state/AppState'
import { Edit } from '../Editor/index.styles'
import { StudentPageState } from '../index.state'
import InviteFollower from './components/InviteFollower'
import useData from './useData'

const Wrapper = styled.div`
  position: relative;
  color: #fff;
  > * {
  }
  > :nth-child(2) {
    padding: 0;
  }
  > :last-child {
    text-align: center;
  }
`

const OwnershipUsers = styled.div`
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  margin-bottom: 15px;
`

const OwnershipUser = styled.div`
  font-size: 11px;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OwnershipUserActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const DeleteButton = styled.div``

const Title = styled.div`
  margin-bottom: 10px;
`

const Content = styled.div``

const AccountManagersSection = () => {
  const [editing, setEditing] = useState(false)
  const { student, updateStudent } = StudentPageState.useContainer()
  const { removeManagedZookeeper } = AppState.useContainer()
  const { removeAccountOwnership } = useData()
  const { confirm: confirmDelete } = useConfirmModal(
    <>
      <h2>Delete follower</h2>
      <p>Are you sure you would like to delete this follower.</p>
      <p>This person will no longer see the work or progress of this child.</p>
      <p>This cannot be undone.</p>
    </>
  )

  return (
    <Wrapper>
      <Title>Followers</Title>
      <Content>
        <OwnershipUsers>
          {!student.accountOwnerships ||
            (student.accountOwnerships.filter(a => a).length === 0 && <div>None</div>)}
          {(student.accountOwnerships || []).filter(a => a.status !== 'PENDING').map((a) => (
            <OwnershipUser key={a._id}>
              { /* eslint-disable-next-line */ }
              <div>{a.user ? a.user.name : a.email || a.code}</div>
              {editing && (
                <OwnershipUserActions>
                  <DeleteButton>
                    <Button
                      round
                      size="x-small"
                      backgroundColor="#DE1323"
                      onClick={() =>
                        confirmDelete(
                          async () => {
                            await removeAccountOwnership(a._id)
                            updateStudent({
                              accountOwnerships: [
                                ...student.accountOwnerships,
                              ].filter((b) => b._id !== a._id),
                            })
                            removeManagedZookeeper(student.username)
                          },
                          () => {}
                        )
                      }
                    >
                      <Trash />
                    </Button>
                  </DeleteButton>
                </OwnershipUserActions>
              )}
            </OwnershipUser>
          ))}
        </OwnershipUsers>
        {editing && <InviteFollower cancel={() => setEditing(false)} />}
      </Content>
      {!editing && <Edit onClick={() => setEditing((d) => !d)} />}
    </Wrapper>
  )
}

export default AccountManagersSection
