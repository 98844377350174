import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import CancelIcon from '../../../UI/Icons/Cancel'
import TickIcon from '../../../UI/Icons/Tick'
import Input from '../../../UI/Input'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
// @ts-ignore
import UPDATE_NICKNAME from './updateNickname.graphql'

const Wrapper = styled(EditorElement)``

const Nickname = styled.div`
  font-size: 16px;
`

interface ChangeNicknameProps {
  error: Function
  success: Function
}

const ChangeNickname = (props: ChangeNicknameProps) => {
  const { student, updateStudent, permissions } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const client = useApolloClient()
  const [nickname, setNickname] = useState('')

  const save = async () => {
    if (nickname.length <= 0) {
      setError('An error has occured.')
      props.error('Nickname length must be greater than 0')
    }
    if (error) return
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_NICKNAME,
        variables: {
          id: student._id,
          nickname,
        },
      })
      if (data && data.updateUser._id) {
        updateStudent({ nickname })
        setEnabled(false)
        props.success(`Nicknamed successfully changed to ${nickname}`)
      }
    } catch (err) {
      setError('An error has occured.')
      props.error('An error has occured.')
    }
  }

  const handleEnabled = () => {
    props.error('')
    props.success('')
    setEnabled(!enabled)
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <div>Nickname:</div>
          <Nickname>{student.nickname || 'Not set'}</Nickname>
        </div>
        {permissions.details.update && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        <div>
          <p>Nickname:</p>
          <Input
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value)
              setError(null)
            }}
          />
        </div>
        <EditingActions>
          <CancelIcon
            size={32}
            color="#EC1A18"
            onClick={() => {
              handleEnabled()
            }}
          />
          <TickIcon size={32} color="#8CC63F" onClick={save} />
        </EditingActions>
      </div>
    </Wrapper>
  )
}

export default ChangeNickname
