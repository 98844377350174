import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit-icon-button.svg'

export const EditorElement = styled.div`
  position: relative;
  width: auto;
  display: inline-block;
  font-size: 13px;
  padding: 10px 5px;
  margin: 0px -5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  > :first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const EditingActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  > * {
    position: relative;
    margin-right: 25px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const EditWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`

export const Edit = (props: any) => {
  return <EditWrapper {...props}>
    <EditIcon height='100%' width='100%' />
  </EditWrapper>
}
