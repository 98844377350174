import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import useCache from 'hooks/useCache'
import GET_LESSONS_PAGE from './graphql/getLessonsPage.graphql'
import GET_LESSONS_DATA from './graphql/getLessonsData.graphql'
import GET_LESSONS_RANGE from './graphql/getLessonsRange.graphql'
import { useStudentPage } from '../../../index.state'
import useRange from '../../hooks/useRange'

interface ILesson {
  _id: string
  title: string
  backgroundUrl: string
  completion: number
  tags: {
    _id: string
    value: string
    color: string
  }[]
}

interface ILessonsStats {
  nbCompleted: number
  nbStarted: number
}

interface ILessonsRangeStats {
  timestamp
  nbCompleted: number
  nbStarted: number
}

const LessonsState = () => {
  const client = useApolloClient()
  const { student } = useStudentPage()
  const [lessons, setLessons] = useState<ILesson[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [lessonsStats, setLessonsStats] = useState<ILessonsStats | null>(null)
  const [loadingStats, setLoadingStats] = useState(false)

  const [thisMonthLessonsStats, setThisMonthLessonsStats] = useState<ILessonsStats | null>(null)

  const [loadingStatsRange, setLoadingStatsRange] = useState(false)
  const [lessonsStatsRange, setLessonsStatsRange] = useState<ILessonsRangeStats[]>([])
  const { rangeSettings, nextMonth, previousMonth, canNext, canPrevious } = useRange({ minDate: student.createdAt })

  const LAST_REFRESH_CACHE_KEY = `${student.username}_reading`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  const PAGE_SIZE = 6

  const fetchPage = async (page: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_LESSONS_PAGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        skip: PAGE_SIZE * (page - 1),
        limit: PAGE_SIZE
      },
      errorPolicy: 'all'
    })
    return (data?.findUser?.userLessonsV2 || []).map(u => u.lesson)
  }


  const loadPage = async (page: number, refetch?: boolean) => {
    if (loadingMore) return
    setLoadingMore(true)
    const lessons = await fetchPage(page, refetch)
    setLoadingMore(false)
    setHasMore(lessons.length >= PAGE_SIZE)
    setLessons(w => ([ ...w, ...lessons]))
    return true
  }

  const refreshFirstPage = async (refetch?: boolean) => {
    const fetched = await fetchPage(1, refetch)
    setLessons((lessons) => {
      const lastMap = lessons.slice(0, PAGE_SIZE).reduce((acc, lesson) => {
        acc[lesson._id] = lesson
        return acc
      }, {})
      const { existing, added } = fetched.reduce((acc, lesson) => {
        if (lastMap[lesson._id]) {
          acc.existing.push(lesson)
        } else {
          acc.added.push(lesson)
        }
        return acc
      }, { existing: [], added: [] })
    
      return [
        ...added,
        ...existing,
        ...lessons.slice(PAGE_SIZE, lessons.length - 1)
      ]
    })
  }

  const loadLessonsStats = async (refetch?: boolean) => {
    setLoadingStats(true)
    const thisMonthTo = moment().startOf('day')
    const thisMonthFrom = moment().startOf('month')
    const { data } = await client.query({
      query: GET_LESSONS_DATA,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        thisMonthTo,
        thisMonthFrom
      },
      errorPolicy: 'all'
    })
    if (data.findUser.lessonsStats) {
      setLessonsStats(data.findUser.lessonsStats.stats)
      setThisMonthLessonsStats(data.findUser.lessonsStats.thisMonth)
    }
    setLoadingStats(false)
  }

  const loadStatsRange = useCallback(async (refetch?: boolean) => {
    setLoadingStatsRange(true)
    const { data } = await client.query({
      query: GET_LESSONS_RANGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from: rangeSettings.from,
        to: rangeSettings.to,
        by: rangeSettings.by
      },
      errorPolicy: 'all'
    })
    if (data.findUser.lessonsStats) {
      setLessonsStatsRange(data.findUser.lessonsStats.range)
    }
    setLoadingStatsRange(false)
  }, [rangeSettings])

  useEffect(() => {
    if (rangeSettings.updated) loadStatsRange()
  }, [rangeSettings])

  const refresh = useMemo(() => {
    if (canRefresh) {
      return () => {
        loadStatsRange(true)
        loadLessonsStats(true)
        refreshFirstPage(true)
        update(Date.now())
      }
    }
    return undefined
  }, [canRefresh])

  return {
    loadPage,
    lessons,
    loadLessonsStats,
    loadingStats,
    lessonsStats,
    thisMonthLessonsStats,
    loadStatsRange,
    rangeSettings,
    nextMonth,
    previousMonth,
    lessonsStatsRange,
    loadingStatsRange,
    canNextMonth: canNext,
    canPreviousMonth: canPrevious,
    hasMore: !loadingMore && hasMore,
    loadingMore,
    refresh,
    lastRefresh,
  }
}

const LessonsContainer = createContainer(LessonsState)
export const LessonsProvider = LessonsContainer.Provider
export const useLessons = LessonsContainer.useContainer