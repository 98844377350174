import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import useCache from 'hooks/useCache'
import GET_CHALLENGES_PAGE from './graphql/getChallengesPage.graphql'
import GET_CATEGORIES_STATS from './graphql/getCategoriesStats.graphql'
import GET_CURRICULUM_DATA from './graphql/getCurriculumData.graphql'
import GET_CHALLENGES_SCORES from './graphql/getChallengesScores.graphql'
import { useStudentPage } from '../../../index.state'
import useRange from '../../hooks/useRange'

interface IChallenge {
  _id: string
  score: number
  type: string
  nbMistakes: number
  nbQuestions: number
  createdAt: Date
  challenge: {
    _id: string
    learningGoal: {
      _id: string
      title: string
      category: {
        _id: string
        title: string
      }
    }
  }
}

interface CategoryStatsData {
  category: {
    _id: string,
    title: string,
  },
  nbChallengesCompleted: number,
  score: number
}

interface ChallengeStats {
  averageScore: number,
  nbChallengesCompleted: number
}

interface ChallengeScore {
  timestamp: Date
  averageScore: number
  nbChallengesCompleted: number
}

const ChallengesState = () => {
  const client = useApolloClient()
  const { student } = useStudentPage()
  const [challenges, setChallenges] = useState<IChallenge[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [loadingData, setLoadingData] = useState(true)
  const [categoriesData, setCategoriesData] = useState<CategoryStatsData[]>([]) 

  const { rangeSettings, previousMonth, nextMonth, canNext, canPrevious } = useRange({ minDate: student.createdAt })

  const [loadingStats, setLoadingStats] = useState(true)
  const [thisMonthStats, setThisMonthStats] = useState<ChallengeStats | null>(null)
  const [overallStats, setOverallStats] = useState<ChallengeStats | null>(null)

  const [loadingScores, setLoadingScores] = useState<boolean>(false)
  const [scores, setScores] = useState<ChallengeScore[]>([])

  const PAGE_SIZE = 6

  const LAST_REFRESH_CACHE_KEY = `${student.username}_challenges`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  const fetchPage = async (page: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_CHALLENGES_PAGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      errorPolicy: 'all',
      variables: {
        username: student.username,
        skip: PAGE_SIZE * (page - 1),
        limit: PAGE_SIZE
      }
    })
    return data?.findUser?.curriculumChallenges || []
  }

  const loadPage = async (page: number) => {
    if (loadingMore) return
    setLoadingMore(true)
    const challenges = await fetchPage(page)
    setLoadingMore(false)
    setHasMore(challenges.length >= PAGE_SIZE)
    setChallenges(w => ([ ...w, ...challenges ]))
    return true
  }

  const refreshFirstPage = async (refetch?: boolean) => {
    const fetched = await fetchPage(1, refetch)
    setChallenges((challenges) => {
      const lastMap = challenges.slice(0, PAGE_SIZE).reduce((acc, challenge) => {
        acc[challenge._id] = challenge
        return acc
      }, {})
      const { existing, added } = fetched.reduce((acc, challenge) => {
        if (lastMap[challenge._id]) {
          acc.existing.push(challenge)
        } else {
          acc.added.push(challenge)
        }
        return acc
      }, { existing: [], added: [] })
    
      return [
        ...added,
        ...existing,
        ...challenges.slice(PAGE_SIZE, challenges.length - 1)
      ]
    })
  }

  const fetchData = async (refetch?: boolean) => {
    setLoadingData(true)
    const today = new Date()
    const from = new Date('2020-10-01')
    const to = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    const { data } = await client.query({
      query: GET_CATEGORIES_STATS,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from,
        to,
      },
      errorPolicy: 'all'
    })
    if (data.findUser.curriculumGroup) {
      // setNbChallengesLastWeek(data.findUser.curriculumGroup.stats.challengesData.nbChallengesCompleted)
      setCategoriesData(data.findUser.curriculumGroup.stats.categoriesData)
    }
    setLoadingData(false)
  }

  const fetchStats = async (refetch?: boolean) => {
    setLoadingStats(true)
    const thisMonthTo = moment().startOf('day')
    const thisMonthFrom = moment().startOf('month')
    const { data } = await client.query({
      query: GET_CURRICULUM_DATA,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        thisMonthFrom,
        thisMonthTo,
      },
      errorPolicy: 'all'
    })
    if (data.findUser.curriculumStats) {
      setOverallStats(data.findUser.curriculumStats.stats)
      setThisMonthStats(data.findUser.curriculumStats.thisMonthStats)
    }
    setLoadingStats(false)
  }

  const loadScores = useCallback(async (refetch?: boolean) => {
    setLoadingScores(true)
    const { data } = await client.query({
      query: GET_CHALLENGES_SCORES,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from: rangeSettings.from,
        to: rangeSettings.to,
        by: rangeSettings.by
      },
      errorPolicy: 'all'
    })
    if (data.findUser.curriculumStats) {
      setScores(data.findUser.curriculumStats.scores)
    }
    setLoadingScores(false)
  }, [rangeSettings])

  useEffect(() => {
    if (rangeSettings.updated) {
      loadScores()
    }
  }, [rangeSettings])


  const refresh = useMemo(() => {
    if (canRefresh) {
      return () => {
        fetchData(true)
        fetchStats(true)
        loadScores(true)
        refreshFirstPage(true)
        update(Date.now())
      }
    }
    return undefined
  }, [canRefresh])

  return {
    // Stats
    fetchStats,
    loadingStats,
    thisMonthStats,
    overallStats,

    // Pages
    loadPage,
    challenges,
    hasMore: !loadingMore && hasMore,
    loadingMore,
  
    // Categories Data
    fetchData,
    categoriesData,
    loadingData,
    
    // Range
    scores,
    loadScores,
    loadingScores,
    previousMonthScores: previousMonth,
    nextMonthScores: nextMonth,
    canNextMonth: canNext,
    canPreviousMonth: canPrevious,
    rangeSettings,
    
    refresh,
    lastRefresh,
  }
}

const ChallengesContainer = createContainer(ChallengesState)
export const ChallengesProvider = ChallengesContainer.Provider
export const useChallenges = ChallengesContainer.useContainer