import { useApolloClient } from '@apollo/client'
import UsernameSelector from 'components/pages/UsernameSelector'
import { navigate } from 'gatsby'
import useConfirmModal from 'hooks/useConfirmModal'
import { Button, Checkmark, Close } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
import UPDATE_USERNAME from './updateUsername.graphql'

const Wrapper = styled(EditorElement)`
`

const Username = styled.div`
  font-size: 16px;
`

const UpdateSection = styled.div`
  margin-bottom: -15px;
  .title {
    font-size: 22px;
  }
  .disclaimer {
    font-family: 'Libre Baskerville';
  }
`

const NewUsernameSectionModal = styled.div`
  width: 300px;
  font-weight: bold;
  font-family: 'Rammetto One';
  margin-top: 10px;
  .new-username {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  margin-bottom: 20px;
`

const NewUsernameSection = styled.div`
  .new-username {
    font-size: 18px;
    text-align: center;
    font-family: 'Rammetto One';
  }
  margin-bottom: 20px;
`

interface ChangeUsernameProps {
  error: Function
  success: Function
}

const ChangeUsername = (props: ChangeUsernameProps) => {
  const { student, updateStudent, isManagedWithOwnership } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const client = useApolloClient()
  const [username, setUsername] = useState('')

  const save = async () => {
    if (username.length <= 0) {
      setError('An error has occured.')
      props.error('Username length must be greater than 0')
    }
    if (error) return
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USERNAME,
        variables: {
          id: student._id,
          username,
        },
      })
      if (data && data.updateUser._id) {
        updateStudent({ username })
        setEnabled(false)
        navigate(`/student/${username}?page=settings`)
        props.success(`Usernamed successfully changed to ${username}`)
      }
    } catch (err) {
      setError('An error has occured.')
      props.error('An error has occured.')
    }
  }

  const { confirm } = useConfirmModal(
    <>
      <h2>Are you sure</h2>
      <p>This change cannot be undone and cannot be changed a second time. The username will be changed across Night Zookeeper.</p>
      <NewUsernameSectionModal>
        <div>New Username:</div>
        <div className='new-username'>{username}</div>
      </NewUsernameSectionModal>
      <p>Your child will need this to log in so keep a note of it.</p>
      <p>By confirming, the previous username will be LOST FOREVER.</p>
    </>
  )

  const handleEnabled = () => {
    props.error('')
    props.success('')
    setEnabled(!enabled)
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <div>Username:</div>
          <Username>{student.username || 'Not set'}</Username>
        </div>
        {!isManagedWithOwnership && !student.lastUsernameChangeAt && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        <UpdateSection>
          <div className='title'>Change Username</div>
          <p className='disclaimer'>Each child may only change their username once and CANNOT RETURN TO A PREVIOUS USERNAME.</p>
          <UsernameSelector onChange={(username) => setUsername(username)} />
        </UpdateSection>
        <NewUsernameSection>
          <div>New Username:</div>
          <div className='new-username'>{username}</div>
        </NewUsernameSection>
        <EditingActions style={{ marginTop: 0 }}>
          <Button theme='red' size='regular' round onClick={() => {
              handleEnabled()
            }}>
            <Close />
          </Button>
          <Button theme='confirm' size='regular' round onClick={() =>
            {
              confirm(
                () => save(),
                () => {}
              )
            }
          }>
            <Checkmark />
          </Button>
        </EditingActions>
      </div>
    </Wrapper>
  )
}

export default ChangeUsername
