import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fff;
  height: 240px;
  width: 100%;
  > * { font-size: initial; }
  background-color: #00000033;
  color: #ffffff88;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default () => {
  return <Wrapper>
    No data this month.
  </Wrapper>
}
