import { getLuminance } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Thumbnail from './Thumbnail'

const Wrapper = styled.div`
  background-color: #1E2958;
  padding: 15px 0 30px 0;
  border-radius: 14px;
  width: 100%;
}
`

const Content = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 10px 20px 10px;
  > * { margin-bottom: 25px; }
  > :last-child { margin-bottom: 25px; }
  section {
    font-size: 18px;
    line-height: 30px;
    > :first-child {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 8px;
    }
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 8px; margin-bottom: 8px; }
`

const Tag = styled.div`
  border-radius: 50px;
  padding: 2px 12px;
  font-size: 14px;
`

interface IProps {
  lesson: {
    _id: string
    title: string
    backgroundUrl: string
    tags: {
      _id: string
      value: string
      color: string
    }[]
    completion: number
  }
}

const LessonCard = (props: IProps) => {
  const { lesson } = props

  return <Wrapper>
    <Content>
      <Thumbnail
        title={lesson.title}
        backgroundUrl={lesson.backgroundUrl}
        completion={lesson.completion}
      />
      { lesson.tags && lesson.tags.length > 0 && <section className='first-section'>
        <div>Learning focus:</div>
        <Tags>
          { lesson.tags.map(tag => <Tag key={tag._id} style={{
            backgroundColor: tag.color,
            color: getLuminance(tag.color) > 0.5 ? '#000' : '#ffff'
          }}>{tag.value}</Tag>)}
        </Tags>
      </section> }
    </Content>
  </Wrapper>
}

LessonCard.defaultProps = {
}

export default LessonCard
