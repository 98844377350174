import React from 'react'
import styled from 'styled-components'
import RatioBox from '../RatioBox'

const Wrapper = styled.div`
  border-radius: 25px;
  overflow: hidden;
  background-color: #341644;
  box-shadow: 0 6px 0 rgba(0,0,0,0.3);
  margin-bottom: 6px;
`

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-color: #7C1120;
  box-shadow: 0 6px 0 rgba(0,0,0,0.3);
`

const Title = styled.div`
  color: #ffffff;
  padding: 8px 15px 30px 15px;
`

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.4);
  > :first-child {
    background-color: #E5247E;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`

interface IProps {
  backgroundUrl: string
  title: string
  completion: number
}

const Thumbnail = (props: IProps) => {
  return <Wrapper>
    <RatioBox ratio='16:9'>
      <ImageWrapper>
        <Image src={props.backgroundUrl} />
        <ProgressBar>
          <div style={{ width: `${(props.completion || 0) * 100}%` }} />
        </ProgressBar>
      </ImageWrapper>
    </RatioBox>
    <Title>
      { props.title }
    </Title>
  </Wrapper>
}

export default Thumbnail
