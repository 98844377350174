import { useApolloClient } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CancelIcon from '../../../UI/Icons/Cancel'
import TickIcon from '../../../UI/Icons/Tick'
import Select from '../../../UI/Select'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
import UPDATE_USER from './updateUser.graphql'

const MODES = [
  { value: 'NONE', label: "Off - Don't highlight mistakes" },
  { value: 'LIGHT', label: 'Light - Highlight some mistakes' },
  { value: 'FULL', label: 'Full - Highlight all mistakes' }
]

const Wrapper = styled(EditorElement)``

const Info = styled.div`
  font-size: 15px;
  font-family: 'Libre Bakerville';
  margin-top: 10px;
  color: #afafaf;
`

const ChangeSpellCheckMode = () => {
  const { student, updateStudent, permissions } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const client = useApolloClient()
  const [spellCheckMode, setSpellCheckMode] = useState(student.kidSettings?.writing?.spellCheckMode || 'LIGHT')

  useEffect(() => {
    setSpellCheckMode(student.kidSettings?.writing?.spellCheckMode || 'LIGHT')
  }, [student])

  const save = async () => {
    if (error) return

    const input = {
      _id: student._id,
      kidSettings: {
        writing: {
          spellCheckMode,
        }
      },
    }
    await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        user: input,
      },
    })
    
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          user: input,
        }
      })
      if (data && data.updateUser._id) {
        updateStudent({
          kidSettings: {
            writing: {
              ...student.kidSettings.writing,
              spellCheckMode,
            }
          }
        })
      
        setEnabled(false)
      }
    } catch (err) {
      setError('An error has occured.')
    }
  }

  const handleEnabled = () => {
    setEnabled(!enabled)
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <div>Spell Check Mode</div>
          <p>{MODES.filter(l => l.value === spellCheckMode)[0]?.label || 'Light'}</p>
        </div>
        {permissions.details.update && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <div>
        <div>Spell Check Mode</div>
        <Select
          options={MODES}
          value={spellCheckMode}
          onChange={setSpellCheckMode}
        />
        <Info>
          We check the spelling of your child's writing after they press "Done" in the writing tool.
        </Info>
        <EditingActions>
          <CancelIcon
            size={32}
            color="#EC1A18"
            onClick={() => {
              handleEnabled()
            }}
          />
          <TickIcon size={32} color="#8CC63F" onClick={save} />
        </EditingActions>
      </div>
    </Wrapper>
  )
}

export default ChangeSpellCheckMode
