import { Button } from 'nzk-react-components'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import React from 'react'
import styled from 'styled-components'
import Loader from 'components/UI/Loader'

export interface NumbersCardProps {
  bgColor: string
  actions?: {
    label: string,
    buttonProps?: ButtonProps
    onClick: () => void,
  }[]
  children?: any
  numbers: {
    label: string,
    value: string
  }[]
  loading?: boolean
}

const Wrapper = styled.div`
  color: #fff;
  display: inline-block;
  border-radius: 13px;
  padding: 20px 0px 10px 0;
  min-width: 310px;
  max-width: 360px;
  width: 100%;
`

const Numbers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  > :nth-child(1) {background-color: rgba(255, 255, 255, 0.05); }
  > :nth-child(2) {background-color: rgba(255, 255, 255, 0.07); }
  > :nth-child(3) {background-color: rgba(255, 255, 255, 0.10); }
  > :nth-child(4) {background-color: rgba(255, 255, 255, 0.14); }
`

const Number = styled.div`
  padding: 6px 20px;
  > :first-child {
    line-height: 14px;
    font-size: 11px;
    margin-bottom: 7px;
    min-height: 28px;
  }
  > :last-child {
    font-size: 21px;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`

const Content = styled.div``

const NumbersCard = (props: NumbersCardProps) => {
  return <Wrapper style={{ backgroundColor: props.bgColor }}>
    <Numbers>
      { props.numbers.map((n, i) => <Number key={i}>
        { props.loading
          ? <>
            <div>{n.label}</div>
            <div>
            <Loader minHeight={24} size={20} color='#ffffff55' />
            </div>
          </>
          : <>
            <div>{n.label}</div>
            <div>{n.value}</div>
          </>
        }
        
      </Number>)}
    </Numbers>
    { props.children && <Content>
      { props.children }
    </Content> }
    { props.actions && <Actions>
      {
        props.actions.map((action, i) => {
          return <Button key={i} theme='primary' size='x-small' {...action.buttonProps} onClick={action.onClick}>
            { action.label }
          </Button>
        })
      }
    </Actions> }
  </Wrapper>
}

NumbersCard.defaultProps = {
  actions: [],
  children: null,
  loading: false
}

export default NumbersCard
