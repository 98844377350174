import { groupBy } from 'lodash'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import { FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import { ThemeState } from '../../../../../../state/ThemeState'
import useCurriculum from '../useCurriculum'
import LearningGoalProgress, { Progress } from './LearningGoalProgress'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
`

const Title = styled.div`
  font-size: 21px;
  font-family: 'Rammetto One';
  color: #fff;
  margin-bottom: 25px;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  > * {
    margin-bottom: 20px;
  }
  margin-bottom: 30px;
`

const SectionTitle = styled.div`
  text-align: center;
  width: 100%;
  background-color: #61237D;
  padding: 8px 12px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  max-width: 860px;
  > :first-child {
    font-size: 18px;
    font-family: 'Rammetto One', cursive;
    text-transform: capitalize;
  }
`

const Stat = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MoreDetails = styled.div`
  > * {
    text-transform: capitalize;
  }
`

const LearningGoals = (props) => {
  const { student, loading } = useCurriculum()
  const [expanded, setExpanded] = useState([])
  const { theme } = ThemeState.useContainer()

  if (loading || !student.stats) return null

  const grouped = groupBy(student.stats.learningGoalsData, l => l.learningGoal.category.title)
  const CATEGORIES = Object.keys(grouped)
  const GOALS = Object.values(grouped)
  const NB = GOALS.map((goals, i) => ({
    index: i,
    ...goals.reduce((acc, goal, i) => ({
      score: acc.score + goal.score,
      nbChallengesTodo: acc.nbChallengesTodo + goal.nbChallengesTodo,
      nbChallengesCompleted: acc.nbChallengesCompleted + goal.nbChallengesCompleted
    }), { score: 0, nbChallengesTodo: 0, nbChallengesCompleted: 0 })
  })).sort((a, b) => (a.nbChallengesCompleted / (a.nbChallengesTodo + a.nbChallengesCompleted)) - (b.nbChallengesCompleted / (b.nbChallengesTodo + b.nbChallengesCompleted)))

  const toggleExpand = (index) => {
    let newExpanded = [...expanded]
    if (newExpanded.indexOf(index) >= 0) {
      newExpanded = newExpanded.filter(d => d !== index)
    } else {
      newExpanded = [...newExpanded, index]
    }
    setExpanded(newExpanded)
  }


  if (CATEGORIES.length === 0) {
    return <Wrapper>
      <Title>Come back later to see how much your child is learning!</Title>
    </Wrapper>
  }
  return <Wrapper>
    <Title>Learning Goals practiced so far:</Title>
    <Content>
      {
        NB.map((c, i) => {
        const isExpanded = expanded.indexOf(i) >= 0
        const category = CATEGORIES[c.index]
        return <Section key={category}>
        <SectionTitle>
          <div>{category}</div>
          <div>
            <div>{c.nbChallengesCompleted} challenges completed</div>
            <div>Accuracy: <FormattedNumber value={c.score / GOALS[c.index].length * 100} maximumFractionDigits={1} />%</div> 
          </div>
        </SectionTitle>
          <Stat>
            <div>Challenges Completed - {c.nbChallengesCompleted}</div>
            <Progress percent={c.nbChallengesCompleted / (c.nbChallengesTodo + c.nbChallengesCompleted)}/>
          </Stat>
          <Stat>
            <div>Accuracy Score - <FormattedNumber value={c.score / GOALS[c.index].length * 100} maximumFractionDigits={1} />%</div>
            <Progress percent={c.score / GOALS[c.index].length}/>
          </Stat>
          <MoreDetails onClick={() => toggleExpand(i)}>
            <Button primary theme='purple' size='x-small'>{ isExpanded ? 'Less' : 'More' } details</Button>
          </MoreDetails>
          {
            isExpanded && GOALS[c.index]
              .sort((a, b) => b.nbChallengesCompleted / (b.nbChallengesCompleted + b.nbChallengesTodo) - a.nbChallengesCompleted / (a.nbChallengesCompleted + a.nbChallengesTodo) )
              .map(l =>
                <LearningGoalProgress
                  key={l.learningGoal._id}
                  title={l.learningGoal.title}
                  score={l.score}
                  nbTodo={l.nbChallengesTodo}
                  nbCompleted={l.nbChallengesCompleted}
                />
              )
          }
        </Section> })
      }
    </Content>
  </Wrapper>
}

LearningGoals.propTypes = {

}

LearningGoals.defaultProps = {

}

export default LearningGoals
