import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import useData from 'components/pages/StudentPage/AccountManagersSection/useData'
import { StudentPageState } from 'components/pages/StudentPage/index.state'
import { Avatar, Button, Close } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { AppState } from '../../../../../../state/AppState'

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  box-shadow: 0 -4px 0 #4B1B68, 0 4px 0 #1B0525, 0 8px 0 rgba(0,0,0,0.4);
  color: #fff;
  border-radius: 20px;
  max-width: 550px;
  padding: 20px 0px;
  font-family: "Libre Baskerville";
  p {
    padding: 0 20px;
  }
  @media (min-width: 1040px) {
    max-width: 100%;
  }
  a {
    color: inherit;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
`

const Title = styled.div`
  font-family: "Rammetto One";
  text-shadow: 0px 5px #afafaf, 0px 10px #120219;
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
`

const Content = styled.div`
  background-color: #ffffff05;
  padding: 10px 15px 20px 15px;

`

const Description = styled.div`
  padding: 0 15px;
  margin-bottom: 20px;
`

const Users = styled.div`
  text-align: center;
  > * { margin-bottom: 16px; }
  > :last-child { margin-bottom: 0; }
`

const User = styled.div`
  display: flex;
  > :first-child {
    margin-right: 16px;
  }
  > :nth-child(2) {
    > :first-child {
      font-size: 21px;
    }
    padding-top: 16px;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`


interface IProps {
  dismiss: () => void,
  data: {
    selectedOwnerships: IAccountOwnership[],
    takesEffect: 'IMMEDIATE' | 'PERIOD_END'
  },
  zookeeper: {
    nickname?: string,
    username: string,
    avatar: { url: string },
    accountOwnerships: IAccountOwnership[],
  }
}

const ConfirmTransfer = (props: IProps) => {
  const { data, zookeeper } = props
  const [loading, setLoading] = useState(false)
  const { updateStudent } = StudentPageState.useContainer()
  const { addManagedZookeeper } = AppState.useContainer()
  const { updatePermissions, transferOwnershipImmediate } = useData()

  const transferImmediate = async () => {
    if (data.selectedOwnerships.length > 1) return
    setLoading(true)
    await transferOwnershipImmediate(data.selectedOwnerships[0]._id)
    updateStudent({
      parentId: data.selectedOwnerships[0].user._id
    })
    addManagedZookeeper({
      ...data.selectedOwnerships[0],
      zookeeper
    })
    setLoading(false)
  }

  const transferOnPeriodEnd = async () => {
    await Promise.all(data.selectedOwnerships.map(async (o) => {
      if (o.permissions.canTakeOwnership) return
      await updatePermissions(o._id, { ...o.permissions, canTakeOwnership: true })
    }))
  }

  const onSubmit = async () => {
    if (data.takesEffect === "IMMEDIATE") {
      await transferImmediate()
      props.dismiss()
      return
    }
    if (data.takesEffect === 'PERIOD_END') {
      await transferOnPeriodEnd()
      props.dismiss()
      return
    }
    props.dismiss()
  }

  return <Wrapper onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
    }}>
      <CloseButton onClick={props.dismiss}>
        <Button theme="red" size="small" round>
          <Close />
        </Button>
      </CloseButton>
      <Title>
        Confirmation
      </Title>
      <Description>
        { data.takesEffect === 'IMMEDIATE' && <p>Choose confirm below to send an email to:</p> }
        { data.takesEffect === 'PERIOD_END' && <p>If you choose confirm below, when your subscription expires, we will send an email to:</p> }
        <Users>
        {
          data.selectedOwnerships.map(o => <div>{o.user ? o.user.name : o.email}</div>)
        }
        </Users>
        <p>Inviting them to become the owner of this child:</p>
      </Description>
      <Content>
        <User>
          <Avatar user={zookeeper} size='110px' />
          <div>
            <div>{zookeeper.nickname}</div>
            <div>{zookeeper.username}</div>
          </div>
        </User>
      </Content>
      { data.takesEffect === 'IMMEDIATE' && <div>
        <p>You will become a follower for this child <b>immediately</b> and <b>the child's account will expire.</b></p>
        <p>The follower invited above can <b>reactivate</b> the child's account from within their dashboard.</p>
      </div> }
      { data.takesEffect === 'PERIOD_END' && <div>
        <p>If they <b>reactivate</b> the child's account, you will become a follower for this child (and can stop following at any time).</p>
      </div>}
      <Actions>
        {
          loading
            ? <Button size='small' theme='primary'>Loading...</Button>
            : <Button size='small' theme='primary' onClick={onSubmit}>Confirm</Button>
        }
      </Actions>
    </Wrapper>
}

export default ConfirmTransfer
