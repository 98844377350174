import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import React from 'react'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  totalCompleted: number,
  // eslint-disable-next-line react/no-unused-prop-types
  completedThisMonth: number,
  // eslint-disable-next-line react/no-unused-prop-types
  totalAccuracy: number,
  // eslint-disable-next-line react/no-unused-prop-types
  accurracyThisMonth: number,
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

const NUMBERS = (props: IProps) => [
  { label: 'Total completed', value: `${props.totalCompleted}` },
  { label: 'Completed this month', value: `${props.completedThisMonth}` },
  { label: 'Total Correct Average', value: `${Math.round(props.totalAccuracy * 100)}%` },
  { label: 'Average this month', value: `${Math.round(props.accurracyThisMonth * 100)}%` },
]

export const PRIMARY = '#7C1120'

const ChallengeNumbersCard = (props: IProps) => {
  return <NumbersCard bgColor={PRIMARY} numbers={NUMBERS(props)} actions={props.actions} loading={props.loading} />
}

ChallengeNumbersCard.defaultProps = {
  actions: [],
  loading: false,
}

export default ChallengeNumbersCard
