import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import CancelIcon from '../../../UI/Icons/Cancel'
import TickIcon from '../../../UI/Icons/Tick'
import Input from '../../../UI/Input'
import { StudentPageState } from '../index.state'
import { Edit, EditingActions, EditorElement } from './index.styles'
// @ts-ignore
import UPDATE_PASSWORD from './updatePassword.graphql'

const Wrapper = styled(EditorElement)``

interface ChangePasswordProps {
  error: Function
  success: Function
}

const ChangePassword = (props: ChangePasswordProps) => {
  const { student, updateStudent, permissions } =
    StudentPageState.useContainer()
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const client = useApolloClient()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const save = async () => {
    if (confirmPassword !== password) {
      setError('Confirm password must be the same as password.')
      props.error('Confirm password must be the same as password.')
    } else if (password.length < 5) {
      props.error('Password length must be greater than 4')
    }
    if (error) return
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          id: student._id,
          password,
        },
      })
      if (data && data.updateUser._id) {
        updateStudent({ password })
        props.success('Password successfully updated')
        setEnabled(false)
      }
    } catch (err) {
      setError('An error has occured.')
      props.error('An error has occured.')
    }
  }

  const handleEnabled = () => {
    props.error('')
    props.success('')
    setEnabled(!enabled)
  }

  if (!enabled) {
    return (
      <Wrapper>
        <div>
          <p>Password:</p>
          <div>******</div>
        </div>
        {permissions.details.update && <Edit onClick={handleEnabled} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <div>
        <p>Password:</p>
        <Input
          type="password"
          placeholder="*****"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
            setError(null)
          }}
        />
        <p>Confirm Password:</p>
        <Input
          type="password"
          placeholder="*****"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value)
            setError(null)
          }}
        />
        <EditingActions>
          <CancelIcon
            size={32}
            color="#EC1A18"
            onClick={() => {
              handleEnabled()
            }}
          />
          <TickIcon size={32} color="#8CC63F" onClick={save} />
        </EditingActions>
        <p>Minimum length is 4 characters</p>
        <p>Passwords are case sensitive</p>
      </div>
    </Wrapper>
  )
}

export default ChangePassword
