import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'

export interface IRangeSettings {
  from: Date
  to: Date
  by: 'DAY' | 'MONTH' | 'YEAR'
  updated?: boolean
}

interface IOptions {
  minDate?: Date
}

const useRange = (options?: IOptions) => {
  const [rangeSettings, setRangeSettings] = useState<IRangeSettings>({
    to: moment().endOf('month').toDate(),
    from: moment().startOf('month').toDate(),
    by: 'DAY'
  })

  const updateRangeSettings = ({ from, to }: { from: Date, to: Date }) => {
    setRangeSettings(s => ({ ...s, from: from || s.from , to: to || s.to, updated: true }))
  }

  const nextMonth = useCallback(() => {
    const nextM =  moment(rangeSettings.from).add('1', 'month')
    const from = moment(nextM).startOf('month').toDate()
    const to = moment(nextM).endOf('month').toDate()
    updateRangeSettings({ from, to })
  }, [rangeSettings])

  const previousMonth = useCallback(() => {
    const lastM =  moment(rangeSettings.from).subtract('1', 'month')
    const from = moment(lastM).startOf('month').toDate()
    const to = moment(lastM).endOf('month').toDate()
    updateRangeSettings({ from, to })
  }, [rangeSettings])

  const canPrevious = useMemo(() => {
    if (!options?.minDate) return true
    return new Date(options.minDate) < new Date(rangeSettings.from)
  }, [rangeSettings])

  const canNext = useMemo(() => {
    return rangeSettings.to < new Date()
  }, [rangeSettings])

  return {
    rangeSettings,
    nextMonth,
    previousMonth,
    canNext,
    canPrevious
  }
}

export default useRange
