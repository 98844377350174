import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
`

interface IProps {
  children: any
}

const EmptyFeed = (props: IProps) => {
  return <Wrapper>
    { props.children }
  </Wrapper>
}

export default EmptyFeed
