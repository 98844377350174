import React from 'react'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  totalPlayed: number,
  // eslint-disable-next-line react/no-unused-prop-types
  playedThisMonth: number,
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

export const PRIMARY = '#B70620'

const NUMBERS = (props: IProps) => [
  { label: 'Total played', value: `${props.totalPlayed}` },
  { label: 'Played this month', value: `${props.playedThisMonth}` },
]
const GameNumbersCard = (props: IProps) => {
  return <NumbersCard actions={props.actions} bgColor={PRIMARY} numbers={NUMBERS(props)} loading={props.loading} />
}

GameNumbersCard.defaultProps = {
  actions: [],
  loading: false,
}

export default GameNumbersCard
