import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from "react"
import { StudentPageState } from "../../index.state"
import GET_DATA from './getData.graphql'

export default () => {
  const { student, updateStudent } = StudentPageState.useContainer()
  const [loading, setLoading] = useState(false)
  const [nbChallengesLastWeek, setNbChallengesLastWeek] = useState(0)
  const client = useApolloClient()
  let today = new Date()
  let from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

  useEffect(() => {
    if (!(student && student.username)) return
    fetchData()
    // eslint-disable-next-line
  }, [student && student.username])

  const fetchData = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_DATA,
      variables: {
        username: student.username,
        from: new Date('2020-01-01'),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
      },
      errorPolicy: 'all'
    })
    if (data.findUser.curriculumGroup) {
      setNbChallengesLastWeek(data.findUser.curriculumGroup.stats.challengesData.nbChallengesCompleted)
      updateStudent({ stats: data.findUser.curriculumGroup.stats })
    }
    setLoading(false)
  }

  return { loading, student, stats: student.stats, from, nbChallengesLastWeek, lastWeek: from }
}
