
import React, { useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { ArrowLeft, ArrowRight, Button, Refresh } from 'nzk-react-components'
import styled from 'styled-components'
import USERNAME_GENERATOR from './graphql/usernameGenerator.graphql'
import USERNAME_EXISTS from './graphql/usernameExists.graphql'

const Content = styled.div`
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  margin-bottom: 8px;
`

const Form = styled.div`
  margin-bottom: 33px;
  input {
    color: #000;
  }
`

interface IInputProps {
  value: string,
  previous?: () => void,
  next?: () => void,
  randomize?: () => void
}

const Username = styled.span`
  text-transform: capitalize;
`

const InputWrapper = styled.div`
  display: flex;
  width: 280px;
  align-items: center;
  > :first-child, > :last-child {
    z-index: 1;
  }
  > :first-child {
    margin-right: -50px;
  }
  > :last-child {
    margin-left: -50px;
  }
`

const TextInput = styled.input`
  height: 36px;
  width: 100%;
  text-align: center;
  border-radius: 18px;
  border: none;
  background-color: #fff !important;
  font-family: 'Libre Baskerville';
`

const Input = (props: IInputProps) => {
  return <InputWrapper>
    { props.previous && <Button size='regular' theme='primary' round onClick={() => props.previous && props.previous()}>
      <ArrowLeft />
    </Button> }
    { props.randomize && <Button size='regular' theme='primary' round onClick={() => props.randomize && props.randomize()}>
      <Refresh />
    </Button> }
    <TextInput type='text' value={props.value}disabled />
    { props.next && <Button size='regular' theme='primary' round onClick={() => props.next && props.next()}>
      <ArrowRight />
    </Button> }
    {
      !props.next && <div />
    }
  </InputWrapper>
}

Input.defaultProps = {
  previous: null,
  next: null,
  randomize: null,
}

interface IUsernameSelectorProps {
  onChange: (username: string) => void
}

const UsernameSelector = (props: IUsernameSelectorProps) => {
  const client = useApolloClient()
  const [adjectiveIndex, setAdjectiveIndex] = useState(0)
  const [animalIndex, setAnimalIndex] = useState(0)
  const [number, setNumber] = useState(`${Math.floor(Math.random() * 100)}`)
  const [animals, setAnimals] = useState([])
  const [adjectives, setAdjectives] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const { data } = await client.query({
        query: USERNAME_GENERATOR,
        variables: {
          number: 20
        }
      })
      setAnimals(data.usernameSuggestions.animals)
      setAdjectives(data.usernameSuggestions.adjectives)
    }
    fetch()
  }, [])

  const animal = useMemo(() => {
    return animals[animalIndex]
  }, [animals, animalIndex])

  const adjective = useMemo(() => {
    return adjectives[adjectiveIndex]
  }, [adjectives, adjectiveIndex])

  const username = useMemo(() => {
    return `${adjective}${animal}${number}`
  }, [animal, adjective, number])

  const getRandomNumber = () => {
    return `${Math.floor(Math.random() * 100)}`
  }

  const usernameIsValid = async (username) => {
    const { data } = await client.query({
      query: USERNAME_EXISTS,
      variables: { username }
    })
    return !data.findUser
  }

  useEffect(() => {
    const fetch = async () => {
      props.onChange(username)
      const valid = await usernameIsValid(username)
      if (!valid) {
        setNumber(getRandomNumber())
      }
    }
    fetch()
  }, [username])


  return <Content>
      <Title>
        <div><Username>{adjective}{animal}{number}</Username></div>
      </Title>
      <Form>
        <Input
          value={adjectives[adjectiveIndex]}
          previous={() => setAdjectiveIndex(i => i > 0 ? i - 1 : adjectives.length - 1)}
          next={() => setAdjectiveIndex(i => (i + 1) % adjectives.length)} />
        <Input
          value={animals[animalIndex]}
          previous={() => setAnimalIndex(i => i > 0 ? i - 1 : animals.length - 1)}
          next={() => setAnimalIndex(i => (i + 1) % animals.length)} />
        <Input
          value={number}
          randomize={() => setNumber(getRandomNumber())} />
      </Form>
    </Content>
}

export default UsernameSelector
