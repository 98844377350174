import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import LessonCard from 'components/UI/LessonCard'
import Loader from '../../../../../UI/Loader'
import { useLessons } from './useLessons'
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Rammetto One', cursive;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  > * {
    place-self: flex-start;
  }
`

const LessonsList = () => {
  const { lessons, loadPage, hasMore } = useLessons()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new lessons...' />}
      >
        { lessons.length === 0 && <EmptyFeed>No lessons to see!</EmptyFeed>}
        <Grid>
          {lessons.map((lesson) => (
            <LessonCard key={lesson._id} lesson={lesson} />
          ))}
        </Grid>
      </InfiniteScroll>
    </Wrapper>
  )
}

export default LessonsList
