import { navigate } from 'gatsby'
import { Button, Comment, Compass, Controller, Heart, Lessons, Message, Nzk, NzkPurple, Projects, Trophy, Upload, Writing } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StudentPageState } from '../index.state'
import BatchPermissionsModal from './components/BatchPermissionsModal'
import { IHookReturn } from './components/Selector'
import SelectorGroup from './components/SelectorGroup'
import useCommentingControl from './hooks/useCommentingControl'
import useFriendsControl from './hooks/useFriendsControl'
import useParentLocksControl from './hooks/useParentLocksControl'
import useSharingControl from './hooks/useSharingControl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  h2 {
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: -30px;
    margin-right: auto;
    font-size: 16px;
    text-align: left;
  }
`

const Title = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-top: 25px;
  text-shadow: 0px 3px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1040px) {
    display: grid;
    margin: 0 auto;
    margin-bottom: 66px;
    > * {
      height: 600px;
    }
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  margin-bottom: 66px;
`

const BigMessage = styled.div`
  margin: 30px 0;
  color: #fff;
`

const Actions = styled.div``

const EasyModeContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  max-width: 900px;
  em {
    color: #c989e7;
    font-style: normal;
  }
  p {
    text-align: center;
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
  }
`

const StudentPageFeatures = () => {
  const {
    permissions,
    currentUser,
    student,
    toggleYoungKidMode
  } = StudentPageState.useContainer()
  const [displayedBatchPermissionsModal, setDisplayedBatchPermissionsModal] = useState(false)

  if (
    !permissions.privacy.update &&
    !permissions.access.update &&
    !permissions.activities.update
  ) {
    return (
      <Wrapper>
        <Title>Manage Features</Title>
        <BigMessage>
          You don't have access to manage this child's features.
        </BigMessage>
        <Actions>
          <Button theme="primary" size="small" onClick={() => navigate('/')}>
            Back to dashboard
          </Button>
        </Actions>
      </Wrapper>
    )
  }
  if (student.youngKidMode) {
    return (
      <Wrapper>
        <Title>Manage Features</Title>
        <EasyModeContainer>
          <p>This account is in <em>Easy Mode</em></p>
          <Button onClick={toggleYoungKidMode} theme="primary" size="small">
            Enable Advanced Mode
          </Button>
          <p>This adds longer form writing and lessons to this account.</p>
        </EasyModeContainer>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Title>Manage Features</Title>
      { student.yearGroup <= 1 && (<EasyModeContainer>
        <p>This account is in <em>Advanced Mode</em>.</p>
        <Button onClick={toggleYoungKidMode} theme="purple" size="x-small">
          Switch to Easy Mode
        </Button>
        <p>This removes longer form writing and lessons from this account.</p>
      </EasyModeContainer>)}
      { displayedBatchPermissionsModal && <BatchPermissionsModal dismiss={() =>setDisplayedBatchPermissionsModal(false)} /> }
      <Center>
        <Button size='x-small' theme='primary'onClick={() => {
          setDisplayedBatchPermissionsModal(true)
        }}>Copy settings to other children</Button>
      </Center>
      <Content>
        {!permissions.privacy.update &&
          !permissions.access.update &&
          !permissions.activities.update && (
            <div>You don't have access to update this child settings.</div>
          )} 
        {permissions.privacy.update && (
          <div>
            <h2>Privacy</h2>
            <SelectorGroup
              editable={permissions.privacy.update}
              selectors={[
                {
                  icon: (
                    <Button height={60} backgroundColor="#6F1EA9" round>
                      <Heart />
                    </Button>
                  ),
                  title: 'Friends',
                  description: 'Follow other users and have followers',
                  helpText: `If enabled, children can add and remove friends they are following and others can follow them. You can add/remove individual friends from the 'Friends' menu in the dashboard too.
              Children are notified when a friend shares something, making it easier to keep up to date with what they post. However, no conversations are possible in real time, all content is moderated and approved first.`,
                  hook: (): IHookReturn => useFriendsControl(),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#009EE2" round>
                      <Nzk />
                    </Button>
                  ),
                  title: 'Sharing',
                  description:
                    'User can share work for other Night Zookeeper to see.',
                  helpText: `If disabled, all the child's work will only be visible to you, them and our tutors. If enabled, their profile, and tutor approved, shared work will be visible to other logged in Night Zookeepers. Your child can choose whether a piece of writing is 'private' or 'shared' each time they submit.`,
                  hook: useSharingControl,
                },
                // {
                //   icon: <Share color="#009EE2" size={60} />,
                //   title: 'Public Sharing',
                //   description:
                //     'Night Zookeeper can feature work publicly on the internet.',
                //   helpText: `If enabled, Night Zookeeper share work, such as star writing and other competition winning work, via social media so that it can be read by those not logged in. You may also wish to make individual pieces of work visible externally (e.g. to share a link with another parent or grandparent). Disable this feature to prevent this being possible.`,
                //   hook: usePublicSharingControl,
                // },
                {
                  icon: (
                    <Button height={60} backgroundColor="#E94E1B" round>
                      <Trophy />
                    </Button>
                  ),
                  title: 'Leagues',
                  description: 'Users see their position in leagues',
                  helpText: `As children do activities on Night Zookeeper they get points which determine their position in our monthly league table. Disable this feature to remove links within the child's account to the league tables.`,
                  hook: (): IHookReturn => useParentLocksControl('leagues'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#009EE2" round>
                      <Comment />
                    </Button>
                  ),
                  title: 'Commenting',
                  description: 'Other users can comment on shared writing',
                  helpText: `If this is enabled, other Night Zookeepers will be able to write comments on writing this child has shared. ALL COMMENTS ARE MODERATED. If disabled, the child will only receive comments from Night Zookeeper tutors or animals.`,
                  hook: (): IHookReturn => useCommentingControl(),
                },
              ]}
            />
          </div>
        )}
        {permissions.access.update && (
          <div>
            <h2>Sections</h2>
            <SelectorGroup
              editable={permissions.access.update}
              selectors={[
                {
                  icon: (
                    <Button height={60} backgroundColor="#F79326" round>
                      <Compass />
                    </Button>
                  ),
                  title: 'Zoo',
                  description: 'Access to the zoo map and animal enclosures',
                  helpText: `The zoo is where all the animals a child creates live and provides access to each animal's den from which learning activities are suggested. If disabled, the child will not be able to visit their zoo or animals, nor create/collect new animals.`,
                  hook: (): IHookReturn => useParentLocksControl('zoo'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#6F1EA9" round>
                      <NzkPurple />
                    </Button>
                  ),
                  title: 'Blog',
                  description: 'Showcase of writing from our community',
                  helpText: `Night Zookeeper chooses some pieces of work to showcase in each blog. A child can only see the blog for their 'team' (each child is automatically assigned randomly to one of our teams when they join).`,
                  hook: (): IHookReturn => useParentLocksControl('blog'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#E94E1B" round>
                      <Message />
                    </Button>
                  ),
                  title: 'Messages',
                  description: 'Messages from our team or friends',
                  helpText: `A child receives notifications in the 'Messages' section for various reasons such as: from Night Zookeeper, when the child receives a comment on a piece of work, when a friend has a new piece of work published or a friend invites a child to a new project. Messages cannot be sent direct from user to user. Disable this feature to hide the messages section.`,
                  hook: (): IHookReturn => useParentLocksControl('messages'),
                },
              ]}
            />
          </div>
        )}
        {permissions.activities.update && (
          <div>
            <h2>Activity Types</h2>
            <SelectorGroup
              editable={permissions.activities.update}
              selectors={[
                {
                  icon: (
                    <Button height={60} backgroundColor="#579800" round>
                      <Projects />
                    </Button>
                  ),
                  title: 'Projects',
                  description: 'Projects from our team or friends',
                  helpText: `Projects are only available by default to children aged 8 and over. In projects, children can share ideas, drawings or writing on the project's topic. They can also create new projects on certain days. Everything is moderated.`,
                  hook: (): IHookReturn => useParentLocksControl('projects'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#28348A" round>
                      <Lessons />
                    </Button>
                  ),
                  title: 'Lessons',
                  description: 'Learn via our videos and interactive lessons.',
                  helpText: `Lessons are created by our education team to teach specific curriculum linked skills. They may include things like short videos, quizzes, writing tasks or drawing tasks targeted at the age group selected for the child.`,
                  hook: (): IHookReturn => useParentLocksControl('nzktv'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#DE1323" round>
                      <Controller />
                    </Button>
                  ),
                  title: 'Games',
                  description:
                    'Playing literacy related games on Night Zookeeper',
                  helpText: `The platform offers a range of literacy related games. Most are played individually whilst a couple allow children to play against each other safely, without any ability to communicate (they only see each other's avatar).`,
                  hook: (): IHookReturn => useParentLocksControl('games'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#579800" round>
                      <Writing />
                    </Button>
                  ),
                  title: 'Writing',
                  description:
                    'Create pieces of writing across a range of topics',
                  helpText: `Children are encouraged to write in a range of styles across the platform. This includes writing inspired by carefully designed text conversations with our Night Zookeeper characters as well writing directly into a blank document. Disabling this will prevent writing challenges from being offered (they may still be encouraged to write within some lessons or occasionally a word or two in some challenges and games).`,
                  hook: (): IHookReturn => useParentLocksControl('write'),
                },
                {
                  icon: (
                    <Button height={60} backgroundColor="#009EE2" round>
                      <Upload />
                    </Button>
                  ),
                  title: 'Upload',
                  description: 'Upload writing and drawing done offline',
                  helpText: `Where they see the upload symbol, children can upload photos of writing or drawing they have done offline. Disable this feature to prevent them from doing this.`,
                  hook: (): IHookReturn => useParentLocksControl('upload'),
                },
              ]}
            />
          </div>
        )}
      </Content>
      {currentUser.children && (
        <Center>
          <Button size='x-small' theme='primary'onClick={() => {
            setDisplayedBatchPermissionsModal(true)
          }}>Copy settings to other children</Button>
        </Center>
      )}
    </Wrapper>
  )
}

export default StudentPageFeatures
