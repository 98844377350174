import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { StudentPageState } from '../index.state'
import FOLLOW from './graphql/follow.graphql'
import GET_CONTENT from './graphql/getContent.graphql'
import REMOVE_FOLLOWER from './graphql/removeFollower.graphql'
import SEARCH_STUDENTS from './graphql/searchStudents.graphql'
import UNFOLLOW from './graphql/unfollow.graphql'

const FriendsState = () => {
  const client = useApolloClient()
  const { student } = StudentPageState.useContainer()
  const [following, setFollowing] = useState<any[]>([])
  const [followers, setFollowers] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const init = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_CONTENT,
      variables: {
        username: student.username,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    const followersMap = (
      (data.findUser && data.findUser.followers) ||
      []
    ).reduce((acc, f) => {
      acc[f._id] = f
      return acc
    }, {})
    const followingMap = (
      (data.findUser && data.findUser.following) ||
      []
    ).reduce((acc, f) => {
      acc[f._id] = f
      return acc
    }, {})
    const followers = Object.keys(followersMap).map((key) => followersMap[key])
    const following = Object.keys(followingMap).map((key) => followingMap[key])
    setFollowers(followers)
    setFollowing(following)
    setLoading(false)
  }

  const searchStudents = async (usernameLike) => {
    const { data } = await client.query({
      query: SEARCH_STUDENTS,
      variables: { usernameLike, skip: 0, limit: 3 },
      fetchPolicy: 'network-only',
    })
    return data.findUsersV2
  }

  const follow = async (user) => {
    const { data } = await client.mutate({
      mutation: FOLLOW,
      variables: {
        sourceUserId: student._id,
        targetUserId: user._id,
      },
    })
    if (data && data.followAddFollowing) {
      setFollowing((following) => [user, ...following])
    }
  }

  const unfollow = async (userId) => {
    const { data } = await client.mutate({
      mutation: UNFOLLOW,
      variables: {
        sourceUserId: student._id,
        targetUserId: userId,
      },
    })
    if (data && data.followRemoveFollowing) {
      setFollowing((following) => following.filter(({ _id }) => _id !== userId))
    }
  }

  const removeFollower = async (userId) => {
    const { data } = await client.mutate({
      mutation: REMOVE_FOLLOWER,
      variables: {
        sourceUserId: student._id,
        targetUserId: userId,
      },
    })
    if (data && data.followRemoveFollowing) {
      setFollowers((followers) => followers.filter(({ _id }) => _id !== userId))
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    init()
  }, [student])

  return {
    following,
    followers,
    searchStudents,
    loading,
    follow,
    removeFollower,
    unfollow,
  }
}

const FriendsStateContainer = createContainer(FriendsState)

export const FriendsStateProvider = FriendsStateContainer.Provider

export const useFriendsState = FriendsStateContainer.useContainer
