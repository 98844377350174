import { useStudentPage } from 'components/pages/StudentPage/index.state'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ChartSettings from '../../components/ChartSettings'
import GameNumbersCard, { PRIMARY } from '../../components/UI/GameNumbersCard'
import Tab from '../Tab'
import Chart from './Chart'
import ScoresList from './ScoresList'
import { useGames } from './useGames'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 40px 0px;
  > .grid {
    > * {
      margin-bottom: 24px;
    }
    > :last-child { margin-bottom: 0; }
    margin-bottom: 24px;
  }
  .section--title {
    font-size: 16pt;
    text-align: left;
    margin-bottom: 15px;
  }
  @media (min-width: 690px) {
    .grid {
      display: grid;
      align-items: start;
      grid-template-columns: 360px 310px;
      grid-gap: 20px;
    }

    .section--no-title {
      padding-top: 40px;
    }
  }
  @media (min-width: 1040px) {
    .grid {
      display: grid;
      align-items: start;
      grid-template-columns: 360px 310px;
      grid-gap: 20px;
    }

    .section--no-title {
      padding-top: 40px;
    }
  }
  .scores-grid {
    width: 100%;
  }
`

const Card = styled.div`
  background-color: ${PRIMARY};
  padding: 15px 20px;
  border-radius: 20px;
`

export const Games = () => {
  const { username } = useStudentPage()
  const {
    overallStats,
    thisMonthStats,
    rangeSettings,
    rangePreviousMonth,
    rangeNextMonth,
    loadStats,
    loadingStats,
    loadRange,
    loadingRange,
    canNextMonth,
    canPreviousMonth,
    range,
    refresh,
    lastRefresh,
  } = useGames()

  useEffect(() => {
    loadStats()
    loadRange()
  }, [username])

  return <Tab title='Games' icon='controller' refresh={refresh} lastRefresh={lastRefresh}>
    <Content>
      <div className='grid'>
        <section>
          <Card>
            <ChartSettings
              settings={rangeSettings}
              previous={rangePreviousMonth}
              canNext={canNextMonth}
              canPrevious={canPreviousMonth}
              next={rangeNextMonth}
            />
            <Chart data={range} loading={loadingRange} by={rangeSettings.by} />
          </Card>
        </section>
        {
          overallStats && thisMonthStats && <section>
            <GameNumbersCard totalPlayed={overallStats.nbPlayed} playedThisMonth={thisMonthStats.nbPlayed} loading={loadingStats} />
          </section>
        }
      </div>
      <section className='scores-grid'>
        <ScoresList />
      </section>
    </Content>
  </Tab>
}

export default Games
