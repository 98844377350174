const experienceFinder = (totalWords, yearGroup) => {
  return Math.floor(totalWords / (yearGroup / 2))
}

const rankObj = {
  0: 'Recruit',
  100: 'Pro',
  300: 'Captain',
  1000: 'Hero',
  3000: 'Legend'
}

const wordRank = Object.keys(rankObj)

export const rankFinder = (totalWords, yearGroup) => {
  const experience = experienceFinder(totalWords, yearGroup)
  return wordRank.reduce((acc, rankValue) => {
    if (experience >= parseInt(rankValue, 10)) {
      return rankObj[rankValue]
    }
    return acc
  }, '')
}

export const rankProgress = (totalWords, yearGroup) => {
  const experience = experienceFinder(totalWords, yearGroup)
  const rankName = rankFinder(totalWords, yearGroup)
  const rankKey = wordRank.find(key => rankObj[key] === rankName)
  if (!rankKey) return 0
  const rankIndex = wordRank.indexOf(rankKey)

  if (experience < 3000) {
    const percentageComplete = (experience - parseInt(rankKey, 10)) / (parseInt(wordRank[rankIndex + 1], 10) - parseInt(rankKey, 10))
    return Math.floor(percentageComplete * 100)
  }
  // assume top rank (Legend) goes up to 10,000
  return Math.floor(((experience - 3000) / 7000) * 100)
}
