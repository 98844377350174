import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../../../../../UI/Loader'
import { useChallenges } from './useChallenges'
import CurriculumChallenge from "../../../../../UI/CurriculumChallenge"
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Rammetto One', cursive;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
`

const ChallengesList = () => {
  const { challenges, loadPage, hasMore, loadingMore } = useChallenges()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new challenges...' />}
      >
        { challenges.length === 0 && !loadingMore && <EmptyFeed>No challenges to see!</EmptyFeed>}
        <Grid>
          {challenges.map((challenge) => (
            <CurriculumChallenge key={challenge._id} challenge={challenge} />
          ))}
        </Grid>
      </InfiniteScroll>
    </Wrapper>
  )
}

export default ChallengesList
