import React from 'react'
import { ButtonProps } from 'nzk-react-components/dist/components/Button/Button'
import NumbersCard from './NumbersCard'

interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  wordCount: number,
  // eslint-disable-next-line react/no-unused-prop-types
  nbArticles: number,
  // eslint-disable-next-line react/no-unused-prop-types
  wordsThisMonth: number,
  // eslint-disable-next-line react/no-unused-prop-types
  articlesThisMonth: number
  actions?: {
    label: string
    buttonProps?: ButtonProps
    onClick: () => void
  }[]
  loading?: boolean
}

const NUMBERS = (props: IProps) => [
  { label: 'Total words', value: `${props.wordCount}` },
  { label: 'Total articles', value: `${props.nbArticles}` },
  { label: 'Words this month', value: `${props.wordsThisMonth}` },
  { label: 'Articles this month', value: `${props.articlesThisMonth}` },
]

export const PRIMARY = '#6E8C1E'

const WritingNumbersCard = (props: IProps) => {
  return <NumbersCard actions={props.actions} bgColor={PRIMARY} numbers={NUMBERS(props)} loading={props.loading} />
}

WritingNumbersCard.defaultProps = {
  actions: [],
  loading: false,
}

export default WritingNumbersCard
