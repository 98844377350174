import { Link } from 'gatsby'
import { Button, IconButton, Close } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { ReactComponent as Settings } from '../../../../../assets/images/icons/icon-settings.svg'
import { StudentPageState } from '../../index.state'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  color: #fff;
  max-width: 320px;
  border-radius: 19px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  padding: 15px 25px 35px 25px;
  > :first-child {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`

const Header = styled.div`
  font-size: 15px;
`

const Content = styled.div`
  font-size: 15px;
  font-family: 'LibreBaskerville';
  > :last-child {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`

interface IProps {
  dismiss: () => void
}

const AboutFriendsFeatureModal = (props: IProps) => {
  const { student } = StudentPageState.useContainer()
  const { dismiss } = props

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'auto'
      }
    }
  }, [])

  return (
    <Overlay onClick={dismiss}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Button size="x-small" backgroundColor="#f00" onClick={dismiss} round>
          <Close />
        </Button>
        <Header>
          <div>Add Child</div>
        </Header>
        <Content>
          <p>
            This feature helps children keep up to date with their friends on
            Night Zookeeper. They are notified when a friend shares something
            new.
          </p>
          <p>
            No conversations are possible in real time, all content is moderated
            and approved first.
          </p>
          <p>
            This feature can be enabled or disabled via the Manage Features
            menu.
          </p>
          <div>
            <Link to={`/student/${student.username}?page=features`}>
              <IconButton
                backgroundColor="#6F1EA9"
                size="x-small"
                icon={<Settings />}
              >
                Manage Features
              </IconButton>
            </Link>
          </div>
        </Content>
      </Wrapper>
    </Overlay>
  )
}

export const useAboutFriendsFeatureModal = () => {
  const [displayed, setDisplayed] = useState(false)

  const toggle = () => setDisplayed((d) => !d)
  const dismiss = () => setDisplayed(false)
  const display = () => setDisplayed(true)

  const component = useMemo(() => {
    if (!displayed) return null
    if (displayed) return <AboutFriendsFeatureModal dismiss={dismiss} />
  }, [displayed])

  return {
    component,
    toggle,
    dismiss,
    display,
  }
}

export default AboutFriendsFeatureModal
