import Animal from 'components/UI/Animal'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../../../../../UI/Loader'
import { useAnimals } from './useAnimals'
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  margin-top: 50px;
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 30px;
`

const AnimalsList = () => {
  const { animals, loadPage, hasMore, loadingMore } = useAnimals()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new animals...' />}
      >
        { animals.length === 0 && !loadingMore && <EmptyFeed>No animals to see!</EmptyFeed>}
        <Grid>
          {animals.map((w) => (
            <Animal key={w._id} animal={w} />
          ))}
        </Grid>
      </InfiniteScroll>
    </Wrapper>
  )
}

export default AnimalsList
