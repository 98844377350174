import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  > :first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #A0891F;
    font-family: 'Rammetto One';
    font-size: 9px;
    color: #fff;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

`

interface IProps {
  book: {
    title: string
    imageUrl: string
  }
  width: number
}

const Book = (props: IProps) => {
  return <Wrapper style={{
    width: props.width ? `${props.width}px` : '80px',
    height: props.width ? `${props.width * 1.5375}px` : `${80 * 1.5375}px`
  }}>
    <div>
      { props.book.imageUrl
        ? <img src={props.book.imageUrl} alt={props.book.title} />
        : <div>{props.book.title}</div>
      }
    </div>
  </Wrapper>
}

export default Book

