import BookRecord from 'components/UI/BookRecord'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../../../../../UI/Loader'
import { useReadings } from './useReadings'
import EmptyFeed from '../../components/UI/EmptyFeed'

const Wrapper = styled.div`
  margin-top: 50px;
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 30px;
`

const ReadingList = () => {
  const { bookRecords, loadPage, hasMore, loadingMore } = useReadings()

  return (
    <Wrapper>
      <InfiniteScroll
        loadMore={loadPage}
        initialLoad
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new records...' />}
      >
        { bookRecords.length === 0 && !loadingMore && <EmptyFeed>No records to see!</EmptyFeed>}
        <Grid>
          {bookRecords.map((w) => (
            <BookRecord key={w._id} bookRecord={w} />
          ))}
        </Grid>
      </InfiniteScroll>
    </Wrapper>
  )
}

export default ReadingList
