import RoundIcon from 'components/UI/RoundIcon'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CONTENT_TABS, useStudentPage } from '../../../index.state'
import useCache from '../../../../../../hooks/useCache'
import AnimalsCard from '../../components/UI/AnimalsCard'
import ChallengeNumbersCard from '../../components/UI/ChallengeNumbersCard'
import DrawingsCard from '../../components/UI/DrawingsCard'
import GameNumbersCard from '../../components/UI/GameNumbersCard'
import LessonsCard from '../../components/UI/LessonsCard'
import ReadingsCard from '../../components/UI/ReadingsCard'
import StudentCard from '../../components/UI/StudentCard'
import WritingNumbersCard from '../../components/UI/WritingNumbersCard'
import { useAnimals } from '../Animals/useAnimals'
import { useChallenges } from '../Challenges/useChallenges'
import { useDrawings } from '../Drawings/useDrawings'
import { useGames } from '../Games/useGames'
import { useLessons } from '../Lessons/useLessons'
import { useReadings } from '../Reading/useReadings'
import Tab from '../Tab'
import { useWriting } from '../Writing/useWriting'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 100%;
  padding: 40px 0px;
`

const Cards = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-gap: 20px;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > .card--title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    > :first-child { margin-right: 5px; }
  }
  &.no-title {
    padding-top: 42px;
  }
`


export const Summary = () => {
  const { username, student, switchTab } = useStudentPage()
  const { loadStats: loadWritingStats, loadingStats: loadingWritingStats, overallStats: overallWritingStats, thisMonthStats: thisMonthWritingStats } = useWriting()
  const { loadLessonsStats, loadingStats: loadingLessonsStats,lessonsStats, thisMonthLessonsStats } = useLessons()
  const { fetchStats: loadChallengesStats, loadingStats: loadingChallengesStats, overallStats: overallChallengesStats, thisMonthStats: thisMonthChallengesStats } = useChallenges()
  const { loadStats: loadGamesStats, loadingStats: loadingGamesStats, overallStats: overallGamesStats, thisMonthStats: thisMonthGamesStats } = useGames()
  const { loadStats: loadDrawingsStats, loadingStats: loadingDrawingsStats,  overallStats: overallDrawingsStats, thisMonthStats: thisMonthDrawingsStats } = useDrawings()
  const { loadStats: loadReadingsStats, loadingStats: loadingReadingsStats,  overallStats: overallReadingsStats, thisMonthStats: thisMonthReadingsStats } = useReadings()
  const { loadStats: loadAnimalsStats, loadingStats: loadingAnimalsStats,  overallStats: overallAnimalsStats, thisMonthStats: thisMonthAnimalsStats } = useAnimals()

  const LAST_REFRESH_CACHE_KEY = `${student.username}_summary`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  useEffect(() => {
    loadWritingStats()
    loadLessonsStats()
    loadChallengesStats()
    loadGamesStats()
    loadDrawingsStats()
    loadReadingsStats()
    loadAnimalsStats()
  }, [username])

  const refresh = () => {
    update(Date.now())
    loadWritingStats(true)
    loadLessonsStats(true)
    loadChallengesStats(true)
    loadGamesStats(true)
    loadDrawingsStats(true)
    loadReadingsStats(true)
    loadAnimalsStats(true)
  }

  return <Tab title='Summary' refresh={canRefresh ? refresh : undefined} lastRefresh={lastRefresh}>
    <Content>
      <Cards>
        <Card className='no-title'>
          <StudentCard
            {...student}
            league={{
              position: student.worldScore?.rank
                ? `${student.worldScore.rank}`
                : 'Unranked'
            }}
            level={student.light?.level || 0} />
        </Card>

        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='writing' />
            <span>Writing</span>
          </div>
          <WritingNumbersCard
            nbArticles={overallWritingStats?.nbArticles || 0}
            wordCount={overallWritingStats?.wordCount || 0}
            wordsThisMonth={thisMonthWritingStats?.wordCount || 0}
            articlesThisMonth={thisMonthWritingStats?.nbArticles || 0}
            loading={loadingWritingStats}
            actions={[
            { label: 'More details', onClick: () => switchTab(CONTENT_TABS.WRITINGS) }
          ]} />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='challenges' />
            <span>Challenges</span>
          </div>
          <ChallengeNumbersCard
            totalCompleted={overallChallengesStats?.nbChallengesCompleted || 0}
            totalAccuracy={overallChallengesStats?.averageScore || 1}
            completedThisMonth={thisMonthChallengesStats?.nbChallengesCompleted || 0}
            accurracyThisMonth={thisMonthChallengesStats?.averageScore || 1}
            loading={loadingChallengesStats}
            actions={[
            { label: 'More details', onClick: () => switchTab(CONTENT_TABS.CHALLENGES) }
          ]} />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='lessons' />
            <span>Lessons</span>
          </div>
          <LessonsCard
            nbCompleted={lessonsStats?.nbCompleted || 0}
            completedThisMonth={thisMonthLessonsStats?.nbCompleted || 0}
            loading={loadingLessonsStats}
            actions={[
            { label: 'More details', onClick: () => switchTab(CONTENT_TABS.LESSONS) }
          ]} />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='drawing-task' />
            <span>Drawings</span>
          </div>
          <DrawingsCard
            nbDrawings={overallDrawingsStats?.nbDrawings || 0}
            drawingsThisMonth={thisMonthDrawingsStats?.nbDrawings || 0}
            loading={loadingDrawingsStats}
            actions={[
              { label: 'More details', onClick: () => switchTab(CONTENT_TABS.DRAWINGS) }
            ]} />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='controller' />
            <span>Games</span>
          </div>
          <GameNumbersCard
            totalPlayed={overallGamesStats?.nbPlayed || 0}
            playedThisMonth={thisMonthGamesStats?.nbPlayed || 0}
            loading={loadingGamesStats}
            actions={[
              { label: 'More details', onClick: () => switchTab(CONTENT_TABS.GAMES) }
            ]}
          />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='torch' />
            <span>Reading</span>
          </div>
          <ReadingsCard
            nbRecords={overallReadingsStats?.nbRecords || 0}
            recordsThisMonth={thisMonthReadingsStats?.nbRecords || 0}
            loading={loadingReadingsStats}
            actions={[
              { label: 'More details', onClick: () => switchTab(CONTENT_TABS.READINGS) }
            ]} />
        </Card>
        
        <Card>
          <div className='card--title'>
            <RoundIcon height={25} icon='my-zoo' />
            <span>Animals</span>
          </div>
          <AnimalsCard
            nbAnimals={overallAnimalsStats?.nbAnimals || 0}
            animalsThisMonth={thisMonthAnimalsStats?.nbAnimals || 0}
            loading={loadingAnimalsStats}
            actions={[
              { label: 'More details', onClick: () => switchTab(CONTENT_TABS.ANIMALS) }
            ]} />
        </Card>

      </Cards>
    </Content>
  </Tab>
}

export default Summary
