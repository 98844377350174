import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import useCache from 'hooks/useCache'
import GET_PAGE from './graphql/getAnimalsPage.graphql'
import GET_STATS from './graphql/getAnimalStats.graphql'
import GET_RANGE from './graphql/getAnimalsRange.graphql'

import { useStudentPage } from '../../../index.state'
import useRange from '../../hooks/useRange'

interface IAnimal {
  _id: string
  createdAt: Date | null,
  name: string | null,
  artwork: { url: string }
}

interface AnimalsStatPoint {
  timestamp?: number
  nbAnimals: number
}

const AnimalState = () => {
  const client = useApolloClient()
  const { student } = useStudentPage()
  const [animals, setAnimals] = useState<IAnimal[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [loadingStats, setLoadingStats] = useState<boolean>(false)
  const [thisMonthStats, setThisMonthStats] = useState<AnimalsStatPoint | null>(null)
  const [overallStats, setOverallStats] = useState<AnimalsStatPoint | null>(null)

  const [loadingRange, setLoadingRange] = useState<boolean>(false)
  const [range, setRange] = useState<AnimalsStatPoint[]>([])
  const { rangeSettings, nextMonth, previousMonth, canNext, canPrevious } = useRange({ minDate: student.createdAt })

  const LAST_REFRESH_CACHE_KEY = `${student.username}_animals`
  const { value: lastRefresh, update, expired: canRefresh } = useCache<number>(LAST_REFRESH_CACHE_KEY, Date.now(), 30)

  const PAGE_SIZE = 6

  const fetchPage = async (page: number, refetch?: boolean) => {
    const { data } = await client.query({
      query: GET_PAGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        skip: PAGE_SIZE * (page - 1),
        limit: PAGE_SIZE
      }
    })
    return data?.findUser?.animals || []
  }

  const loadPage = async (page: number, refetch?: boolean) => {
    if (loadingMore) return
    setLoadingMore(true)
    const animals = await fetchPage(page, refetch)
    setLoadingMore(false)
    setHasMore(animals.length >= PAGE_SIZE)
    setAnimals(d => ([...d, ...animals]))
    return true
  }

  const refreshFirstPage = async (refetch?: boolean) => {
    const fetched = await fetchPage(1, refetch)
    setAnimals((animals) => {
      const lastMap = animals.slice(0, PAGE_SIZE).reduce((acc, animal) => {
        acc[animal._id] = animal
        return acc
      }, {})
      const { existing, added } = fetched.reduce((acc, animal) => {
        if (lastMap[animal._id]) {
          acc.existing.push(animal)
        } else {
          acc.added.push(animal)
        }
        return acc
      }, { existing: [], added: [] })
    
      return [
        ...added,
        ...existing,
        ...animals.slice(PAGE_SIZE, animals.length - 1)
      ]
    })
  }

  const loadStats = async (refetch?: boolean) => {
    setLoadingStats(true)
    const thisMonthTo = moment().startOf('day')
    const thisMonthFrom = moment().startOf('month')
  
    const { data } = await client.query({
      query: GET_STATS,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        thisMonthFrom,
        thisMonthTo,
      },
      errorPolicy: 'all'
    })
    if (data.findUser.animalStats) {
      setOverallStats(data.findUser.animalStats.stats)
      setThisMonthStats(data.findUser.animalStats.thisMonthStats)
    }
    setLoadingStats(false)
  }

  const loadRange = useCallback(async (refetch?: boolean) => {
    setLoadingRange(true)
    const { data } = await client.query({
      query: GET_RANGE,
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
      variables: {
        username: student.username,
        from: rangeSettings.from,
        to: rangeSettings.to,
        by: rangeSettings.by
      },
      errorPolicy: 'all'
    })
    if (data.findUser.animalStats) {
      setRange(data.findUser.animalStats.range)
    }
    setLoadingRange(false)
  }, [rangeSettings])

  useEffect(() => {
    if (rangeSettings.updated) {
      loadRange()
    }
  }, [rangeSettings])

  const refresh = useMemo(() => {
    if (canRefresh) {
      return () => {
        loadStats(true)
        loadRange(true)
        refreshFirstPage(true)
        update(Date.now())
      }
    }
    return undefined
  }, [canRefresh])

  return {
    // Feed
    loadPage,
    loadingMore,
    hasMore: !loadingMore && hasMore,
    animals,
    // Stats
    loadStats,
    loadingStats,
    thisMonthStats,
    overallStats,
    // Range
    loadingRange,
    rangeSettings,
    rangePreviousMonth: previousMonth,
    rangeNextMonth: nextMonth,
    canNextMonth: canNext,
    canPreviousMonth: canPrevious,
    loadRange,
    range,
    refresh,
    lastRefresh,
  }
}

const AnimalsContainer = createContainer(AnimalState)
export const AnimalsProvider = AnimalsContainer.Provider
export const useAnimals = AnimalsContainer.useContainer