import React from 'react'
import { FormattedDate } from 'react-intl'
import styled, { css } from 'styled-components'

interface IProps {
  drawing: {
    _id: string
    title: string | null
    artwork: {
      url: string
    }
    createdAt: Date | null
  }
}

const Wrapper = styled.div`
  font-family: 'Libre Baskerville';
  font-size: 14px;
  > .drawing--date {
    margin-bottom: 10px;
  }
`

const Content = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 5px 0 #afafaf, 0 7px 0 #ebebeb;
  .drawing--title {
    color: #3C3C3B;
    font-size: 18px;
    margin-bottom: 15px;
    font-family: 'Rammetto One';
  }
`


const Thumbnail = styled.div`
  height: 240px;
  ${(props: { src: string }) => css`
    background-image: url("${props.src}");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `}
`

const Drawing = (props: IProps) => {
  if (!props.drawing) return null
  return <Wrapper>
    { props.drawing.createdAt && <div className='drawing--date'><FormattedDate
        value={props.drawing.createdAt}
        month="long"
        year="numeric"
        day="numeric"
        hour12
        hour='numeric'
        minute='numeric'
      /></div>}
    <Content>
      { props.drawing.title && <div className='drawing--title'>{props.drawing.title}</div> }
      <Thumbnail src={props.drawing.artwork.url} />
    </Content>
  </Wrapper>
}

Drawing.defaultProps = {
}

export default Drawing
