import { Link, navigate } from 'gatsby'
import useAmplitude from 'hooks/useAmplitude'
import { Avatar, Button, Eye, IconButton, Transfer } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../../state/CurrentUserState'
import AccountManagersSection from '../AccountManagersSection'
import Age from '../Editor/Age'
import BirthDate from '../Editor/BirthDate'
import ChangeNickname from '../Editor/ChangeNickname'
import ChangePassword from '../Editor/ChangePassword'
import ChangeUsername from '../Editor/ChangeUsername'
import ChangeYearGroup from '../Editor/ChangeYearGroup'
import { findCountry } from '../helpers'
import { StudentPageState } from '../index.state'
import { Content, InfoBox, Wrapper } from '../index.styles'
import TransferOwnershipModal from './components/TransferOwnershipModal'
import ChangeSpellCheckMode from '../Editor/ChangeSpellCheckMode'

const Title = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-top: 28px;
  text-shadow: 0px 3px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
`

const StyledContent = styled(Content)`
  padding-top: 80px;
  > :first-child {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 10px;
  > * {
    margin-right: 12px;
    margin-bottom: 10px;
  }
  > :last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`

const Element = styled.div`
  > :first-child {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
  }
`

const Info = styled.p`
  font-family: 'Libre Baskerville';
  color: #fff;
  a {
    color: inherit;
  }
`

const StudentPageSettings = () => {
  const {
    isExpired,
    student,
    permissions,
    isManagedWithOwnership,
    // takeAccountOwnership
  } =
    StudentPageState.useContainer()
  const { logEvent } = useAmplitude()
  const { currentUser } = CurrentUserState.useContainer()
  const country = findCountry(student.countryCode)
  const [transferingShown, setTransferingShown] = useState(false)

  return (
    <Wrapper>
      <Title>Zookeeper Settings</Title>
      <StyledContent>
        <Avatar user={{ ...student, type: 'student' }} size="110px" />
        <InfoBox>
          <Element>
            <div>
              <ChangeUsername success={() => {}} error={() => {}} />
            </div>
          </Element>
          <Element>
          <div>
            <ChangeNickname success={() => {}} error={() => {}} />
          </div>
          </Element>
          <Element>
          <div>
            <ChangePassword success={() => {}} error={() => {}} />
          </div>
          </Element>
          <Element>
          <div>
            <BirthDate success={() => {}} error={() => {}} />
          </div>
          </Element>
          <Element>
          <div>
            <Age />
          </div>
          </Element>
          <Element>
          <div>
            <ChangeYearGroup success={() => {}} error={() => {}} />
          </div>
          </Element>
          <Element>
          <div>
            <p>Country: </p>
            <p>{country}</p>
          </div>
          </Element>
          <Element>
            <div>
              <ChangeSpellCheckMode />
            </div>
          </Element>
          <Element>
            <div>
              <p>Your status:</p>
              <p>{ isManagedWithOwnership ? 'Follower' : 'Owner' }</p>
            </div>
          </Element>
          { isManagedWithOwnership && isExpired && permissions.canTakeOwnership && <Element>
            <div>
              <p>Child status:</p>
              <p>Expired</p>
              <Info>To stop following, visit <Link to='/account'>Manage Account.</Link> </Info>
            </div>
          </Element> }
          {!isManagedWithOwnership && <Element><AccountManagersSection /></Element>}
          <Actions>
            { isManagedWithOwnership && isExpired && permissions.canTakeOwnership && (
              <Button
                size="small"
                theme='confirm'
                onClick={() => {
                  logEvent('Dashboard: Reactivate', { from: 'Child Settings' })
                  navigate('/welcome?page=SelectAPlan')
                }}
              >
                Reactivate
              </Button>
            )}
            {currentUser && student.parentId === currentUser._id && (
              <IconButton
                size="small"
                backgroundColor="#6F1EA9"
                icon={<Eye />}
                onClick={() => {
                  navigate('/ownerships?tab=MANAGE')
                }}
              >
                Add followers
              </IconButton>
            )}
            {currentUser && student.parentId === currentUser._id && (
              <IconButton
                size="small"
                backgroundColor="#6F1EA9"
                icon={<Transfer />}
                onClick={() => {
                  setTransferingShown(true)
                }}
              >
                Transfer Ownership
              </IconButton>
            )}
          </Actions>
        </InfoBox>
        { transferingShown && <TransferOwnershipModal zookeeper={student} dismiss={() => setTransferingShown(false)} /> }
      </StyledContent>
    </Wrapper>
  )
}

export default StudentPageSettings
