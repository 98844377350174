import { debounce } from 'lodash'
import { Avatar, Button } from 'nzk-react-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as TrashIcon } from '../../../../../assets/images/icons/trash.svg'
import Input from '../../../../UI/Input'
import { useFriendsState } from '../index.state'
import Box from './Box'

const Content = styled.div`
  color: #fff;
  padding: 5px 15px;
  padding-bottom: 30px;
  width: 100%;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 20px;
  }
  > :nth-child(2) {
    max-width: 170px;
  }
  margin-bottom: 15px;
`

const Users = styled.div`
  position: relative;
  min-height: 110px;
  max-width: 960px;
  padding-bottom: 20px;
  display: flex;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff55;
    border-radius: 12pt;
  }
`

const User = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Libre Baskerville';
  > * {
    margin-bottom: 10px;
  }
  > :nth-child(2) {
    position: absolute;
    top: 0px;
    right: 50%;
    transform: translateX(43px);
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const SearchFriend = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  > :first-child {
    max-width: 180px;
    margin-right: 15px;
  }
`

const Elements = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 290px;
  border-radius: 8px;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.4);
  border: 2px solid #832cc4;

  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 3;
  overflow: hidden;
  > :first-child {
    padding-top: 12px;
  }
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
    padding-bottom: 12px;
  }
  > :nth-child(2n + 1) {
    background-color: #ebebeb;
  }
`

const EmptyMessage = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
`

const Element = styled.div`
  display: flex;
  color: #000;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 0 12px;
  justify-content: flex-start;
  > :first-child {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  > :nth-child(2) {
    flex: 1;
  }
  > div > .info {
    font-family: 'Libre Baskerville';
    font-size: 12px;
    color: #aeaeae;
  }
`

const Dropdown = styled.div`
  position: relative;
`

const Student = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  max-width: 220px;
  overflow: hidden;
  justify-content: flex-start;
  > :first-child {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  > :nth-child(2) {
    flex: 1;
  }
`

const Following = () => {
  const [searchValue, setSearchValue] = useState('')
  const [newFriendsSearchValue, setNewFriendsSearchValue] = useState('')
  const [selectedStudent, setSelectedStudent] = useState<any>()
  const { searchStudents, unfollow, following, follow } = useFriendsState()
  const [students, setStudents] = useState([])

  const displayedUsers = useMemo(() => {
    if (searchValue && searchValue.length >= 3) {
      return following.filter(({ username }) =>
        new RegExp(searchValue).test(username)
      )
    }
    return following
  }, [searchValue, following])

  const onSearchFriends = async (usernameLike) => {
    const students = await searchStudents(usernameLike)
    setStudents(students)
  }

  const debouncedOnSearchFriends = useCallback(
    debounce(onSearchFriends, 500),
    []
  )

  useEffect(() => {
    if (newFriendsSearchValue.length <= 3) setStudents([])
    if (newFriendsSearchValue.length > 3) {
      debouncedOnSearchFriends(newFriendsSearchValue)
    }
  }, [newFriendsSearchValue])

  const followSelected = async () => {
    if (!selectedStudent) return
    await follow(selectedStudent)
    setStudents([])
    setSelectedStudent(null)
  }

  return (
    <Box width="100%">
      <Content>
        <Title>
          <div>Following ({following.length})</div>
          {following.length > 0 && (
            <Input
              placeholder="Search friends"
              type="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
        </Title>
        <Users>
          {following.length === 0 && (
            <EmptyMessage>
              This child has not added any friends yet.
            </EmptyMessage>
          )}
          {displayedUsers.map((user) => (
            <User key={user._id}>
              <Avatar user={user} size="80px" />
              <Button
                height={35}
                backgroundColor="#f00"
                onClick={() => unfollow(user._id)}
                round
              >
                <TrashIcon />
              </Button>
              <div>{user.username}</div>
            </User>
          ))}
        </Users>
        <SearchFriend>
          {selectedStudent && (
            <Student onClick={() => setSelectedStudent(null)}>
              <Avatar size="40px" user={selectedStudent} />
              <div>{selectedStudent.username}</div>
            </Student>
          )}
          {!selectedStudent && (
            <Dropdown>
              <Input
                placeholder="Add a new friend"
                value={newFriendsSearchValue}
                onChange={(e) => setNewFriendsSearchValue(e.target.value)}
              />
              {students.length > 0 && (
                <Elements>
                  {students.map((s: any) => {
                    const alreadyFollowing = following.filter(
                      (f) => `${f._id}` === `${s._id}`
                    )[0]
                    return (
                      <Element
                        onClick={() =>
                          !alreadyFollowing && setSelectedStudent(s)
                        }
                      >
                        <Avatar user={s} size="40px" />
                        <div>
                          <div>{s.username}</div>
                          {alreadyFollowing && (
                            <div className="info">Already following</div>
                          )}
                        </div>
                      </Element>
                    )
                  })}
                </Elements>
              )}
            </Dropdown>
          )}
          <Button
            backgroundColor="#67239A"
            size="small"
            disabled={!selectedStudent}
            onClick={followSelected}
          >
            Follow
          </Button>
        </SearchFriend>
      </Content>
    </Box>
  )
}

export default Following
