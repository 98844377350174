// ! FROM OLD ACCOUNT PAGE. To refactor and remove.

const getFrequency = (interval, interval_count) => {
  if (interval === 'year') return 'Yearly'
  if (interval === 'month' && interval_count === 1) return 'Monthly'
  if (interval === 'month' && interval_count === 3) return 'Quarterly'
  if (interval === 'month' && interval_count === 6) return '6 Months'
}

const getCapacity = (nickname) => {
  if (new RegExp('Kids').test(nickname)) return `${nickname.split('kids')[0]} children`
  if (new RegExp('Multiple').test(nickname)) return 'up to 3 children'
  return '1 child'
}

export const getFormattedPlanName = (options) => {
  if (!options) return ''
  const { interval, interval_count, nickname } = options
  const FREQUENCY = getFrequency(interval, interval_count)
  const CAPACITY = getCapacity(nickname)
  return `${FREQUENCY} plan for ${CAPACITY}`
}

export const findCountry = (countryCode) => {
  const Countries = require('../../../../countries.json')
  const filteredObj = Countries.find((item) => {
    if(item.value === countryCode) {
      return item.label
    }
    return null
  })
  if(filteredObj !== undefined) return filteredObj.label
  return countryCode
}

export const getYearGroup = (month, year) => {
  const currentYear = new Date().getUTCFullYear()
  let yearGroup = currentYear - year - (month < 8 ? 3 : 4) - 2
  if (new Date().getMonth() >= 8) {
    yearGroup += 1
  }
  return Math.min(7, Math.max(0, yearGroup))
}