import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import ChallengeTypeHero from './ChallengeTypeHero'

const Wrapper = styled.div`
  font-family: 'Libre Baskerville';
  font-size: 14px;
  > .challenge--date {
    margin-bottom: 10px;
  }
`

const Card = styled.div`
  background-color: #7C1120;
  padding: 15px 0 30px 0;
  border-radius: 14px;
  font-family: 'Rammetto One';
`

const Content = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 10px 20px 10px;
  > * { margin-bottom: 25px; }
  > :last-child { margin-bottom: 25px; }
  section {
    font-size: 18px;
    line-height: 30px;
    > :first-child {
      font-size: 13px;
      line-height: 21px;
    }
  }
  .category {
    background-color: #7C1120;
    display: inline-flex;
    border-radius: 50px;
    padding: 0 10px;
    font-size: 16px;
  }
`

const Results = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  .results--result {
    span {
      font-size: 16px;
    }
  }
  .results--score {

  }
`

interface IProps {
  challenge: {
    _id: string
    score?: number
    type?: string
    nbMistakes?: number
    nbQuestions?: number
    createdAt?: Date
    challenge?: {
      _id: string
      learningGoal?: {
        _id: string
        title: string
        category?: {
          _id: string
          title: string
        }
      }
    }
  }
}

const CurriculumChallenge = (props: IProps) => {
  const { challenge } = props

  return <Wrapper>
  { props.challenge.createdAt && <div className='challenge--date'><FormattedDate
        value={props.challenge.createdAt}
        month="long"
        year="numeric"
        day="numeric"
        hour12
        hour='numeric'
        minute='numeric'
      />
    </div>}
    <Card>
      <Content>
        { challenge.type && <ChallengeTypeHero type={challenge.type} /> }
        <section className='first-section'>
          <div>Learning topic:</div>
          <div>{challenge.challenge?.learningGoal?.title}</div>
        </section>
        <section>
          <div>Learning area:</div>
          <div className='category'>{challenge.challenge?.learningGoal?.category?.title}</div>
        </section>
        <section>
          <div>Results:</div>
          <Results>
            { challenge.nbQuestions !== undefined &&
            challenge.nbMistakes !== undefined && <div className='results--result'>{challenge.nbQuestions - challenge.nbMistakes}/<span>{challenge.nbQuestions}</span></div> }
            { challenge.score !== undefined && <div className='results--score'>{Math.round(challenge.score * 100)}%</div> }
          </Results>
        </section>
      </Content>
    </Card>
  </Wrapper>
}

CurriculumChallenge.defaultProps = {
}

export default CurriculumChallenge
