import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CONTENT_TABS, StudentPageState } from '../index.state'
import Curriculum from './Curriculum'
import Nav from './Nav'
import {
  Animals,
  Challenges,
  Drawings,
  Games,
  Lessons,
  Reading,
  Summary,
  Writing
} from './tabs'
import AdvancedTutoring from './tabs/AdvancedTutoring'
import { AnimalsProvider } from './tabs/Animals/useAnimals'
import { ChallengesProvider } from './tabs/Challenges/useChallenges'
import { DrawingsProvider } from './tabs/Drawings/useDrawings'
import { GamesProvider } from './tabs/Games/useGames'
import { LessonsProvider } from './tabs/Lessons/useLessons'
import { ReadingsProvider } from './tabs/Reading/useReadings'
import { WritingProvider } from './tabs/Writing/useWriting'

const Wrapper = styled.div`
  font-family: 'Rammetto One', cursive;
  width: 100%;
  height: 100%;
`

const Sections = styled.div`
  margin: 0 auto;
  padding: 0 25px;
`

const StudentPageContent = () => {
  const { tab } = StudentPageState.useContainer()
  
  const content = useMemo(() => {
    if (tab === CONTENT_TABS.CURRICULUM) return <Curriculum />
    if (tab === CONTENT_TABS.SUMMARY) return <Summary />
    if (tab === CONTENT_TABS.WRITINGS) return <Writing />
    if (tab === CONTENT_TABS.CHALLENGES) return <Challenges />
    if (tab === CONTENT_TABS.LESSONS) return <Lessons />
    if (tab === CONTENT_TABS.DRAWINGS) return <Drawings />
    if (tab === CONTENT_TABS.READINGS) return <Reading />
    if (tab === CONTENT_TABS.GAMES) return <Games />
    if (tab === CONTENT_TABS.ANIMALS) return <Animals />
    if (tab === CONTENT_TABS.ADVANCED_TUTORING) return <AdvancedTutoring />
    return <Summary />
  }, [tab])
  return (
    <Wrapper>
      <Nav />
      <Sections>
        { content }
      </Sections>
    </Wrapper>
  )
}

export default () => {
  return <ChallengesProvider>
      <LessonsProvider>
        <WritingProvider>
          <ReadingsProvider>
            <GamesProvider>
              <DrawingsProvider>
                <AnimalsProvider>
                  <StudentPageContent />
                </AnimalsProvider>
              </DrawingsProvider>
            </GamesProvider>
          </ReadingsProvider>
        </WritingProvider>
      </LessonsProvider>
    </ChallengesProvider>
}
