import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 25px 0;
  background-color: #481c5f;
  border-radius: 12px;
  width: ${(props: { width?: string }) => props.width};
`

const Content = styled.div`
  background-color: #502566;
`

interface IProps {
  children: ReactElement
  width?: string
}

const Box = (props: IProps) => (
  <Wrapper width={props.width}>
    <Content>{props.children}</Content>
  </Wrapper>
)

Box.defaultProps = {
  width: 'auto',
}

export default Box
