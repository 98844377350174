import { Button, IconButton } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import Friends from '../../../UI/Icons/Friends'
import { ReactComponent as Info } from '../../../UI/Icons/info.svg'
import Loader from '../../../UI/Loader'
import Toggle from '../../../UI/Toggle'
import { StudentPageState } from '../index.state'
import useFriendsControl from '../StudentPageFeatures/hooks/useFriendsControl'
import { useAboutFriendsFeatureModal } from './components/AboutFriendsFeatureModal'
import Followers from './components/Followers'
import Following from './components/Following'
import Overview from './components/Overview'
import { FriendsStateProvider, useFriendsState } from './index.state'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  padding-bottom: 80px;
  padding: 0 10px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Rammetto One';
  font-size: 22px;
  margin-top: 10px;
  text-shadow: 0 4px 0 #aeaeae, 0 8px rgba(0, 0, 0, 0.4);
  > :first-child {
    position: relative !important;
    margin-right: 15px;
  }
`

const Controls = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 960px;
  > * {
    margin-bottom: 35px;
  }
`

const StudentPageFriends = () => {
  const { student } = StudentPageState.useContainer()
  const { followers, following, loading } = useFriendsState()
  const { enabled, toggle } = useFriendsControl()
  const { component: friendsFeatureModal, display } =
    useAboutFriendsFeatureModal()

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    )
  return (
    <Wrapper>
      <Content>
        <Title>
          <Friends color="#6F1EA9" size={40} />
          <div>Friends</div>
        </Title>
        <Controls>
          <Toggle selected={enabled} onChange={toggle} />
          <a
            href={`https://nightzookeeper.com/edu/zoo/profile/${student.username}`}
            rel="noreferrer"
            target="_blank"
          >
            <Button size="x-small" backgroundColor="#6F1EA9">
              Visit Profile
            </Button>
          </a>
        </Controls>
        {
          enabled && <>
            <Overview followers={followers || []} following={following || []} />
            <Following following={following || []} />
            <Followers followers={followers || []} />
          </>
        }
      </Content>
      <IconButton
        theme="primary"
        size="x-small"
        icon={<Info />}
        onClick={display}
      >
        <div>About this feature</div>
      </IconButton>
      {friendsFeatureModal}
    </Wrapper>
  )
}

export default () => (
  <FriendsStateProvider>
    <StudentPageFriends />
  </FriendsStateProvider>
)
