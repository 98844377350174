import { useStudentPage } from 'components/pages/StudentPage/index.state'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ChartSettings from '../../components/ChartSettings'
import ChallengeAccuracyScoreCard from '../../components/UI/ChallengeAccuracyScoreCard'
import ChallengeNumbersCard, { PRIMARY } from '../../components/UI/ChallengeNumbersCard'
import Tab from '../Tab'
import ChallengesList from './ChallengesList'
import Chart from './Chart'
import { useChallenges } from './useChallenges'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 40px 0px;
  > .grid {
    > * {
      margin-bottom: 24px;
    }
    > :last-child { margin-bottom: 0; }
    margin-bottom: 24px;
  }
  .section--title {
    font-size: 16pt;
    text-align: left;
    margin-bottom: 15px;
  }
  @media (min-width: 690px) {
    .grid {
      display: grid;
      align-items: start;
      grid-template-columns: 360px 310px;
      grid-gap: 20px;
    }

    .section--no-title {
      padding-top: 40px;
    }
  }
  @media (min-width: 1040px) {
    .grid {
      display: grid;
      align-items: start;
      grid-template-columns: 360px 310px 310px;
      grid-gap: 20px;
    }

    .section--no-title {
      padding-top: 40px;
    }
  }
  .challenges-grid {
    width: 100%;
  }
`

const Card = styled.div`
  background-color: ${PRIMARY};
  padding: 15px 20px;
  border-radius: 20px;
`

export const Challenges = () => {
  const { username } = useStudentPage()
  const {
    fetchStats,
    thisMonthStats,
    overallStats,
    loadingStats,
    scores,
    loadingScores,
    fetchData,
    categoriesData,
    loadingData,
    loadScores,
    nextMonthScores,
    previousMonthScores,
    rangeSettings,
    canNextMonth,
    canPreviousMonth,
    refresh,
    lastRefresh,
  } = useChallenges()

  useEffect(() => {
    fetchData()
    fetchStats()
    loadScores()
  }, [username])

  return <Tab title='Challenges' icon='challenges' refresh={refresh} lastRefresh={lastRefresh}>
    <Content>
      <div className='grid'>
        <section className='section--no-title'>
          <Card>
            <ChartSettings
              next={nextMonthScores}
              previous={previousMonthScores}
              settings={rangeSettings}
              canNext={canNextMonth}
              canPrevious={canPreviousMonth}
            />
            <Chart data={scores} loading={loadingScores} by='DAY' />
          </Card>
        </section>
        { thisMonthStats && overallStats && <section className='section--no-title'>
          <ChallengeNumbersCard
            loading={loadingStats}
            totalCompleted={overallStats.nbChallengesCompleted}
            totalAccuracy={overallStats.averageScore}
            completedThisMonth={thisMonthStats.nbChallengesCompleted}
            accurracyThisMonth={thisMonthStats.averageScore}
          />
        </section> }
        <section>
          <div className='section--title'>Overview</div>
          <ChallengeAccuracyScoreCard scores={categoriesData} loading={loadingData} />
        </section>
      </div>
      <section className='challenges-grid'>
        <ChallengesList />
      </section>
    </Content>
  </Tab>
}

export default Challenges
